import React, {Fragment, useState, useEffect, useRef} from 'react'
import styles from './index.less'
import {replyAnswer, matchGame} from '../../static/api'
import {toast} from '../../static/util/utils'
import {useTranslation} from "react-i18next";
import {Emoji} from 'emoji-mart';
import {getEmojiIdByName} from '../../static/emojiMap';
import radarLog from '../../static/util/radar'
import { InView } from "react-intersection-observer";
import showdown from 'showdown';
showdown.setFlavor('github');
var converter = new showdown.Converter();



/**
 * @param {String} props.type 区分左右对话框
 * @param {Boolean} props.isMobile 是否移动端
 * @param {Boolean} props.feedback 显示点赞
 * @param {String } props.content 内容
 * @param {Array} props.list 可选内容
 * @param {Object} props.info 角色信息
 * @param {String} props.id feedback回传id
 * @param {Function} props.triggerSend 问题list的点击回调
 * @param {Function} props.triggerGameSend 游戏名字list的点击回调
 * @param {Boolean} props.innerHtml 富文本展示
 */

var tostTip = toast();

const Dialog = (props) => {
  const {
    origin,
    type = "left",
    showFeedback = false,
    isMobile = false,
    content = '',
    contentList = '',
    component: Component = null,
    hideCollapse = true,
    showMore = false,
    chatType,
    list = [],
    gameList = [],
    info = {},
    id = '',
    triggerSend = () => {
    },
    imageList,
    setImageIndex = () => {
    },
    triggerGameSend = () => {
    },
    innerHtml = false,
    scrollToBottom = ()=>{

    },
    loading,
    imInfo,
    setLightUrl = () => {

    },
    postMessage,
    aTarget = false,
    options,
    msgTime,
    msgId,
    setCurrentAutoIMItemID = () => {
    },
    countResponse = () => {

    },
    speakerName,
    showIMBtn = false,
    connectIM = () => {
    },
    prompt,
    disable = false,
    closeTyped = ()=>{
    },
    msgRetry = ()=>{

    },
    canRetry = true,
    isIMConnect,
    _id,
    stopQuestion = false,
    answerType,
    renderType,
  } = props;
  const {t} = useTranslation();
  //展示select框
  const [showSelect, setshowSelect] = useState(false);
  const [gamePlatform, setgamePlatform] = useState('');
  const [collapse, setCollapse] = useState(false);


  //移动端点赞状态控制
  const [likeState, setLikeState] = useState('');

  const dialogRef = useRef();
  const textRef = useRef();

  useEffect(() => {
    // console.log('dialogRef', hideCollapse);
    // debugger;
    //包含图片的内容不显示折叠
    if (textRef.current && textRef.current.clientHeight && textRef.current.clientHeight > 180) {
      // debugger;
      !showMore && !hideCollapse && setCollapse(true);
      // console.log(`${content}`,type, textRef?.current?.clientHeight, showMore, hideCollapse)
    }

    // setCollapse(true);
  }, []);

  // console.log('初始化');

  let obj = {mobile: '手游', wan: '页游', youxi: '端游'}

  function changeName(e, {setGameName, setMatching, setArr, setShowGame}) {
    if (!gamePlatform) {
      tostTip('请先选择游戏平台')
      return;
    }
    setGameName(e.target.value);
    setMatching(true);
    matchGame({keyword: e.target.value, pkey: gamePlatform}).then((res) => {
      var gameList = res.data || []
      setShowGame(gameList.length)
      setArr(gameList)
      // scrollToBottom();
      setMatching(false);
    }).catch((err) => {
      tostTip(err.msg)
      setMatching(false);
    });

  }

  // 选择游戏
  function GameSend(item, {setShowGame, setGameName}) {
    //去掉游戏展示框
    setShowGame(false)
    setGameName(item)
    triggerGameSend(item)
  }

  // 展开游戏平台
  function selectedItem() {
    setshowSelect(!showSelect)
  }

  // document.addEventListener('click',()=>{
  //   console.log(23);

  //   if(showSelect) setshowSelect(false)
  //   if(showGame) setshowGame(false)
  // },true)
  // 选择游戏平台
  function selectedGame(item, event, {setGameName}) {
    //把游戏名称清空
    setGameName('')
    setshowSelect(false)
    setgamePlatform(item)
  }

  //失去焦点
  function initshowGame({setShowGame}) {
    setTimeout(() => {
      setshowSelect(false);
      setShowGame(false)
    }, 300);
  }

  const [clickable, setClickable] = useState(showFeedback) // 反馈可点击
  // 点赞请求
  function feedbackClick(e, reply) {

    if (!clickable) {
      return
    }

    setClickable(false)
    setLikeState(reply + '');

    replyAnswer({id, reply})
    e && $(e.target).siblings().hide(300)

    if (reply === 1 && info && info.kfInfo && info.kfInfo.upContent) {
      tostTip(info.kfInfo.upContent);
    }
    if (reply === 0 && info && info.kfInfo && info.kfInfo.downContent) {
      tostTip(info.kfInfo.downContent);
    }

    if (reply === 0) {
      countResponse(true);
      msgId && setCurrentAutoIMItemID(msgId);
    } else {
      countResponse(false);
    }

  }

  const dialogClickHandle = e => {

    if (e && e.target && e.target.nodeName === 'IMG' && e.target.src) {
      if (e.target.src === 'https://p2.ssl.qhimg.com/t017638ac82012a6f59.png') {
        return;
      }
      let ossSrc;
      if (e.target?.parentNode) {
        ossSrc = e.target.parentNode?.getAttribute('_img_src');
      }
      postMessage ? postMessage({
        type: 'IMG-CLICK',
        src: ossSrc || e.target.src,
      }) : setLightUrl(e.target.src);

      if (
        imageList &&
        imageList.length > 0 &&
        imageList.findIndex(url => url === e.target.src) > -1
      ) {
        setImageIndex(imageList.findIndex(url => url === e.target.src));
      }

    }
  }


  const addATarget = (content, flag) => {
    if (typeof content === 'string') {
      const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      const regex = new RegExp(expression);
      let result = content.replace(regex, function ($url) {
        return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>';
      });
      return flag ? result : content;
    } else {
      return content;
    }


  };

  const addEmojiTarget = (content) => {
    if (typeof content === 'string') {
      const reg = new RegExp('\\[(.+?)\\]', "g");
      let result = content.replace(reg, function ($1, $2) {

        let id = getEmojiIdByName($2);
        if (id) {
          return Emoji({
            html: true,
            set: 'apple',
            emoji: id,
            size: 24,
          });
        } else {
          return $1;
        }


      });

      return result;
    } else {
      return content;
    }
  }

  const convertHtml = (content, target, type,renderType) => {
    let result = content;
    result = addATarget(result);
    result = addEmojiTarget(result);
    if (type && (type === 3 || type === 4)) {
      const stringToSend = result;
      let ele = document.createElement('div');
      ele.innerHTML = stringToSend;
      let msg = ele.innerText || ele.textContent || ele.innerHTML;
      return msg;
    }
    if(renderType === 'markdown'){
      // debugger;
      return converter.makeHtml(result);
    }
    return result
  }

  const convertExtContent = (content) => {
    let arr = [];
    try {
      arr = JSON.parse(content);
    } catch (e) {

    }
    return arr;
  }

  const RenderContentMobile = props => {
    const {content,typedInput = false,renderType} = props;
    const [arr, setArr] = useState([]);
    const [gameName, setGameName] = useState('');
    const [showGame, setShowGame] = useState()
    const [matching, setMatching] = useState(false);
    const [ownCollapse, setOwnCollapse] = useState(false);
    const ownTextRef = useRef();
    const el = useRef(null);
    useEffect(() => {
      if (content && content.match && content.match(/<img.*?(?:>|\/>)/gi)) {

      } else if (ownTextRef.current && ownTextRef.current.clientHeight && ownTextRef.current.clientHeight > 180) {
        !showMore && !hideCollapse && setOwnCollapse(true);
      }
    }, []);
    useEffect(() => {
      if (el.current) {
        if (typedInput) {
          scrollToBottom(0);
        }
      }
      return ()=>{
        closeTyped(msgId);
      }
    }, [el,typedInput,content]);
    return <>
      <div
        ref={ownTextRef}
        className={`${ownCollapse ? styles['collapse_dialog'] : ''} ${styles['dialog_content']} ${Component ? styles['-no-style'] : ''} ${(!showMore && list.length == 0) ? '' : styles['-same_radius']} ${(id && type != 'right' ) || renderType === 'markdown'? styles['-max-width'] : ''}`}

      >

        {!showMore && list.length == 0 ? (
          <Fragment>
            {content ? <>
              {innerHtml || true ?
                (<div ref={el} onClick={dialogClickHandle} className={`kf_dialog-text ${renderType === 'markdown'?'markdown-body':''} `}
                      dangerouslySetInnerHTML={{__html: convertHtml(content, aTarget,null,renderType)}}></div>) :
                (<div className="kf_dialog-text">{content}</div>)}

            </> : null}


            {Component ? <Component {...props} options={options}/> : null}

            {/* 目前仅发送消息增加状态 */}
            {type === 'right' && loading ?
              <div className={`${styles['dialog_loading']} ${loading === 'loading' ? styles['-rotate'] : ''}`}>
                {loading === 'loading'?<img
                  src={`${loading === 'loading' ? 'https://p2.ssl.qhimg.com/t016dd17b0c93618fad.png' : ''}`}/>:null}
              </div> : null}
            {
               (type === 'right' && loading && loading === 'error' && isIMConnect && canRetry) ?
                <div className={`${styles['dialog_loading']} ${styles['-error']} `} onClick={() => {
                  msgRetry(origin)
                }}>
                  <svg className={`icon ${styles['dialog-error-icon']}`} aria-hidden="true" >
                    <use xlinkHref={`#icon-zhike-retry`}/>
                  </svg>
                </div> : null
            }
            { (type === 'right' && loading && loading === 'error' && (!isIMConnect || !canRetry)) ?
              <div className={`${styles['dialog_loading']} ${styles['-error']} `}>
                <img
                  src={`https://p5.ssl.qhimg.com/t01456d9a80c2eb0517.png`}/>
              </div> : null}
          </Fragment>
        ) : (
          <div className={`kf_dialog-text kf_dialog-list`}>
            {
              (content && gameList.length === 0) ?
                <div className="kf_dialog-list-title"
                     onClick={dialogClickHandle}
                     dangerouslySetInnerHTML={{__html: convertHtml(content, aTarget,null,renderType)}}></div> : null
            }

            {list.map((item, k) => <div className="kf_dialog-list-content" key={k}
                                        dangerouslySetInnerHTML={{__html: (typeof item === 'string' ? item : item?.question)}}
                                        onClick={() => {
                                          radarLog({c: 'blo_send'});
                                          triggerSend(typeof item === 'string' ? item : item?.question, item.question_id || item.faq_data_id, {
                                            msgId,
                                            record: item
                                          })
                                        }
                                        }></div>)}
            {!list.length ? (
              <div className="kf_dialog-list-box">

                <div className="kf_dialog-list-select">
                  <p className="kf_dialog-list-select-title">
                    {gameList.length === 0 ? '请选择您要咨询的游戏：' : '咨询其他游戏请选择：'}
                  </p>
                  <div className="kf_dialog-list-select-item">
                    <span>游戏平台：</span>
                    <div className={`kf_dialog-list-select-box ${showSelect ? styles['-active'] : ''}`}>
                      <span className={obj[gamePlatform] ? "kf_dialog-list-select-box-active" : ""} onClick={() => {
                        selectedItem()
                      }}>{obj[gamePlatform] ? obj[gamePlatform] : '请选择游戏平台'}</span>
                      {
                        showSelect ? (
                          <div className="select-list">
                            <p onClick={() => selectedGame('mobile', event, {setGameName})}>手游</p>
                            <p onClick={() => selectedGame('wan', event, {setGameName})}>页游</p>
                            <p onClick={() => selectedGame('youxi', event, {setGameName})}>端游</p>
                          </div>) : null
                      }

                    </div>
                  </div>
                  <div className="kf_dialog-list-select-item">
                    <span>游戏名称：</span>
                    <input value={gameName} placeholder={'请输入游戏名称'} onBlur={() => {
                      initshowGame({setShowGame})
                    }} onChange={(e) => {
                      changeName(e, {
                        setGameName,
                        setMatching,
                        setArr,
                        setShowGame
                      })
                    }} type='text'/>
                    {
                      showGame ?
                        (<div className="kf_dialog-list-select-auto">
                          {arr.map((item, k) => <div onClick={() => GameSend(item, {setGameName, setShowGame})}
                                                     key={k}>{item}</div>)}
                        </div>) : null
                    }
                  </div>
                  {!showGame && gameName && !arr.length && !matching ? (
                    <div
                      className="kf_dialog-list-select-auto-tip">请输入正确的游戏名称，并在下拉框中选择</div>) : null}
                </div>
              </div>
            ) : null}
          </div>
        )}
        {
          ownCollapse ? <div className={styles['collapse-btn']} onClick={() => {
            setOwnCollapse(false)
          }}>
            <span>{t('show-more')}</span>
            <svg className={`icon `} aria-hidden="true">
              <use xlinkHref={`#icon-zhike-arrow-down`}/>
            </svg>
          </div> : null
        }
      </div>
    </>
  }

  const renderMobile = () => {

    if (type === 'weakText') {
      return renderWeakTextMobile();
    }
    let extContent = convertExtContent(contentList);
    if (!(Array.isArray(extContent) && extContent.length > 0)) {
      extContent = [content];
    }
    return (<div ref={dialogRef} className={`${type === 'left' ? styles['mkf_dialog']
      : `${styles['mkf_dialog']} ${styles.u_dialog}`}   `}>
      {type === 'left' ? (
        <Fragment>
          <img className="kf_dialog-pic"
               src={info.kfInfo && info.kfInfo.url || "https://p3.ssl.qhimg.com/t010a6232b25737b10d.png"}/>
          <div
            className={styles['dialog_name']}>{(imInfo && imInfo.nickName) || (info.kfInfo && info.kfInfo.nickName)} {msgTime}</div>
        </Fragment>
      ) : (
        <Fragment>
          <img className="kf_dialog-pic"
               src={info.uInfo && info.uInfo.url || "https://p0.ssl.qhimg.com/t01d63eafe7af7c2615.png"}/>
          <div className={styles['dialog_name']}>{msgTime} {info.uInfo && info.uInfo.name || t('tourist')}</div>
        </Fragment>
      )}

      {/*游戏列表单独写出来 */}
      {
        gameList.length != 0 ? (
          <div className={`${styles['dialog_content']}  ${styles['-same_radius']}`} style={{marginBottom: '.12rem'}}>
            <div className={`kf_dialog-text kf_dialog-list`}>
              <div className="kf_dialog-list-title">请选择您要咨询的游戏</div>
              <div className="kf_dialog-list-list">
                {gameList.map((item, k) => <div className="kf_dialog-list-item" key={k}
                                                onClick={() => triggerGameSend(item)}>{item}</div>)}
              </div>
            </div>
          </div>
        ) : null
      }

      {/* 内容 */}
      <div style={{
        maxWidth: '100%',
        position: 'relative',
        marginBottom: (type === 'left' && (showIMBtn)) ? '.2rem' : ''
      }}>

        {
          (extContent).map((item, index) => {
            return <RenderContentMobile key={index} {...props} content={item}/>
          })
        }

        {
          stopQuestion || answerType > 5 ? <div style={{    width: '100%',
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            gap: '.08rem',
            marginTop: '.04rem'}}>
            {answerType > 5 ?<span style={{background:'#fff',padding:'.02rem .06rem',borderRadius:'.04rem',fontSize:'.12rem',lineHeight:'1.25',color:'rgb(150,150,150)'}}>内容由AI生成，仅供参考</span>:null}
            {stopQuestion ?<span style={{background:'#fff',padding:'.02rem .06rem',borderRadius:'.04rem',fontSize:'.12rem',lineHeight:'1.25',color:'rgb(150,150,150)'}}>已停止回答</span>:null}
          </div>:null
        }

        {disable ? <div className={styles['shadow-container']}></div> : null}
        {/* 点赞 */}
        {type === 'left' && showFeedback && id && (
          <div className={`${styles['dialog_feedback']} ${likeState != '' ? styles['-disable'] : ''}`}>
            <div className={`${styles['feedback_btn']} ${likeState === '1' ? styles['-active'] : ''}`}
                 onClick={() => feedbackClick(null, 1)}>
              <svg className={`icon ${styles['icon']}`} aria-hidden="true">
                <use xlinkHref={`#icon-zhike-like${likeState === '1' ? '-active' : ''}`}/>
              </svg>
              <span dangerouslySetInnerHTML={{__html: t('like')}}></span>
            </div>
            <div className={`${styles['feedback_btn']} ${likeState === '0' ? styles['-active'] : ''}`}
                 onClick={() => feedbackClick(null, 0)}>
              <svg className={`icon ${styles['icon']}`} aria-hidden="true">
                <use xlinkHref={`#icon-zhike-unlike${likeState === '0' ? '-active' : ''}`}/>
              </svg>
              <span dangerouslySetInnerHTML={{__html: t('unlike')}}></span>
            </div>
          </div>
        )}
        {/*转人工按钮*/}
        {
          type === 'left' && (showIMBtn) && (
            <div className="kf_dialog-im" onClick={() => {
              connectIM(false, prompt, null, 1);
              radarLog({c: 'zd_transfer'});
            }}>
              转人工
            </div>
          )
        }
      </div>
    </div>)
  }

  const renderWeakTextMobile = () => {
    return (content ? <div className={styles['mkf_weak-text-container']}>
      {content}
    </div> : null);
  }

  const renderWeakText = () => {
    return (content ? <div className={styles['kf_weak-text-container']}>
      {content}
    </div> : null);
  }


  const RenderContent = (props) => {
    const {
      content,
      typedInput = false,
      _id,
      msgId,
      origin,
      renderType
    } = props;
    const typed = useRef(null);
    const [arr, setArr] = useState([]);
    const [gameName, setGameName] = useState('');
    const [showGame, setShowGame] = useState()
    const [matching, setMatching] = useState(false);
    const [ownCollapse, setOwnCollapse] = useState(false);
    const ownTextRef = useRef();
    const el = useRef(null);
    useEffect(() => {
      if (content && content.match && content.match(/<img.*?(?:>|\/>)/gi)) {

      } else if (ownTextRef.current && ownTextRef.current.clientHeight && ownTextRef.current.clientHeight > 180) {
        !showMore && !hideCollapse && setOwnCollapse(true);
      }
    }, []);

    useEffect(() => {
      if (el.current) {
        if (typedInput) {
          scrollToBottom(0);
          // if(typed && typed.current && typed.current.constructor === Typed){
          //   typed.destroy();
          // }
          // typed.current = new Typed(el.current, {
          //   strings: [convertHtml(content, aTarget, chatType)],
          //   typeSpeed: 100,
          //   showCursor: false,
          //   onComplete(self) {
          //     closeTyped(msgId);
          //   }
          // })
        }
      }
      return ()=>{
        closeTyped(msgId);
      }
    }, [el,typedInput,content]);
    return <>
      <div ref={ownTextRef} className={`kf_dialog-content ${ownCollapse ? styles['collapse_dialog'] : ''}`}
      >

        {disable ? <div className={styles['shadow-container']}></div> : null}
        {!showMore && list.length == 0 ? (
          <Fragment>
            {content  ? <>
              {innerHtml || true ?
                (<div ref={el} onClick={dialogClickHandle} className={`kf_dialog-text
                 ${chatType === 3 ? styles['leave-header'] : ''}
                 ${chatType === 4 ? styles['unusual-leave-header'] : ''}
                 ${renderType === 'markdown'?'markdown-body':''} `}
                      dangerouslySetInnerHTML={{__html: convertHtml(content, aTarget, chatType,renderType)}}></div>) :
                (<div className="kf_dialog-text">{content}</div>)}
            </> : null}
            {/*{*/}
            {/*  typedInput ? <div ref={el} onClick={dialogClickHandle} style={{*/}
            {/*    whiteSpace: 'pre-wrap'*/}
            {/*  }} className={`kf_dialog-text*/}
            {/*     ${chatType === 3 ? styles['leave-header'] : ''}*/}
            {/*     ${chatType === 4 ? styles['unusual-leave-header'] : ''}*/}
            {/*     `}>*/}

            {/*  </div> : null*/}
            {/*}*/}

            {/*{*/}
            {/*  typedInput && <Typist>*/}
            {/*    { content}*/}
            {/*  </Typist>*/}
            {/*}*/}

            {Component ? <div
              className={`
              ${chatType === 3 ? `${styles['leave-header']} ${styles['-component']}` : ''}
              ${chatType === 4 ? `${styles['unusual-leave-header']} ${styles['-component']}` : ''}
               `}
            >
              <Component {...props} />
            </div> : null}

            {/* 目前仅发送消息增加状态 */}
            {type === 'right' && loading && loading === 'loading' ?
              <div className={`${styles['dialog_loading']} ${loading === 'loading' ? styles['-rotate'] : ''}`}>
                <img
                  src={`${loading === 'loading' ? 'https://p2.ssl.qhimg.com/t016dd17b0c93618fad.png' : (loading === 'error' ? 'https://p5.ssl.qhimg.com/t01456d9a80c2eb0517.png' : '')}`}/>
              </div> : null}


            {
              (type === 'right' && loading && loading === 'error' && isIMConnect && canRetry) ?
                <div style={{cursor:'pointer'}} className={`${styles['dialog_loading']} ${styles['-error']} `} onClick={() => {
                  msgRetry(origin)
                }}>
                  <svg className={`icon `} aria-hidden="true" style={{width: 16, height: 16, verticalAlign: 'middle'}}>
                    <use xlinkHref={`#icon-zhike-retry`}/>
                  </svg>
                  <span>重试</span>
                </div> : null
            }
            { (type === 'right' && loading && loading === 'error' && (!isIMConnect || !canRetry)) ?
              <div className={`${styles['dialog_loading']} ${styles['-error']} `}>
                <img
                  src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAROSURBVHgB7ZvNbhNXFMf/5xqppBtStcuqOE9AwgvUtFI3rUTKsmqCQaJY3UC76wrKC4C6IZRFbGDRnbOgXSC1OBJS2SDyBnYQe8ImCcgzhzMztjJfHs/Ycy+ewT/J8cx4nDvn73O/zj0XmDPng4ZgCK43qlLcMmz7JGxakpJPBG/AayjuQqldoN+j5t0dGECbAFy/ugj74DxY1UB2TYpaRCZ4T77bkSfcEmG2qbnRgwZyF0B+6RosXJP/XEOeMJqooCVCdJAjuQmgzfAoPXns63Tvdgs5MLUAbt22sGnA8FDBtIUK/zJt1VCYAl6/fAU2PzduvAPxKmx0eb1xDVMwkQe4DZx12H4vhsfhesNHF6h5aw8ZySyA6/I2HsthFbNFT/z5TNYqkUmAGTZ+SGYRUgtQAOOHZBIhlQDeoObwOWbf+CEiwvGVNG1Cul7AOthEcYx3kK75zWaaG8cK4HYzRKsoGtJN8tpPV8felvThoN53UVhkPqFoJak9SPYAi2+i0MgEzBmlJjBSAF5r1LW7/hefey+dyGCNf5R5ygiOJXxxqiHmWFa/A77/1jveegi0/4Y2PFs6cR/FeoA7s9Pd6g+Nd/jma2glwQviq0AfdZjk4wVoZ4RHRwRwW36F8ygbjhc4A7oQUQ+wZmSGpwNrvx6+FFMF6CzKCqla+FJUADeAWVb4y/CVgABc/3k5e/S2SNCiF54/IugB/X4VZcfmU/7ToABUqBnfZDAv+U9DAlAVZYdU1X8aagTLXP8HhJbkpgqLl4G5APjACQnAmRcWJmZ/33d8AGM4y/A+ggIw92CK9j/euyNE+yGMwXbPfxoMiLCEk02lTDz6F3jyv3fs9wbdEAVinEEBjqkdCYKaw6ThQ5S96z+N/N6y4vuqxOOBPbq38Yn/QrQXcNJSyst2+EJMUNSSm5T+hRAnGnxpEHi62wJevIR+qB2+EvWAytsmTHClcRQW/+1XGEFxxAMiAlCzuSe9QQe6+ezTo2MzQdFm3ApR/EiQ8TvKhoXYpKpYAejBRke7Fzzz5UE++g+a6bo2xTB6ZcjxAp05QH/cAU6f8tz/yVPohUZ6dPLq8Frj8cwkQk0KU5vu3z436uPk2WAFF4xOkPKHUeHELiZRALfVZFXkBvHGuFyhdDlCa5fbhcsSIW5T6865cbelC4hU3khVcHJ0C0MXtHAxzY2pBHAHRwpnUAwRuvKsX6XNGi1bouTAeA2JkkNmWITMxjtkDoq6BajDFQmfbWE2YKfBgzp+epLU+akCYLzeuC5venOJknklMb4bdP/PW5iQfDZMMN0UjzDZTbI7V6ng4rQbJvLbMuOm1bneUIU+PMNZBjgP8tk7lP+mKScbS7lJVnnlGfHgb66GD9G4bc7dS1STo7PehCpToJW9bXNwts1tQy20JtkNkgZTqwDgHy4tQ1FVXiel2CUx7kToSWTFhruweRd9tUN/6dknOGfOnADvABTNa75R6fySAAAAAElFTkSuQmCC`}/>
                <span>消息发送失败</span>
              </div> : null}
          </Fragment>
        ) : (
          <div className="kf_dialog-text kf_dialog-list">
            {
              content ?
                <div className="kf_dialog-list-title"
                     onClick={dialogClickHandle}
                     dangerouslySetInnerHTML={{__html: convertHtml(content, aTarget,null,renderType)}}></div> : null
            }

            {list.map((item, k) => <div className="kf_dialog-list-content" key={k}
                                        dangerouslySetInnerHTML={{__html: (typeof item === 'string' ? item : item?.question)}}
                                        onClick={() => {
                                          radarLog({c: 'blo_send'});
                                          triggerSend(typeof item === 'string' ? item : item?.question, item.question_id || item.faq_data_id, {
                                            msgId,
                                            record: item
                                          })
                                        }}></div>)}
            {!list.length ? (
              <div className="kf_dialog-list-box">
                {
                  gameList.length != 0 ? (
                    <Fragment>
                      <div className="kf_dialog-list-list">
                        {gameList.map((item, k) => <div className="kf_dialog-list-item" key={k}
                                                        onClick={() => triggerGameSend(item)}>{item}</div>)}
                      </div>
                    </Fragment>
                  ) : null
                }
                <div className="kf_dialog-list-select">
                  <p className="kf_dialog-list-select-title">
                    {gameList.length === 0 ? '请选择您要咨询的游戏：' : '咨询其他游戏请选择：'}
                  </p>
                  <div className="kf_dialog-list-select-item">
                    <span>游戏平台：</span>
                    <div className="kf_dialog-list-select-box">
                        <span className={obj[gamePlatform] ? "kf_dialog-list-select-box-active" : ""} onClick={() => {
                          selectedItem()
                        }}>{obj[gamePlatform] ? obj[gamePlatform] : '请选择游戏平台'}</span>
                      {
                        showSelect ? (
                          <div className="select-list">
                            <p onClick={() => selectedGame('mobile', event, {setGameName})}>手游</p>
                            <p onClick={() => selectedGame('wan', event, {setGameName})}>页游</p>
                            <p onClick={() => selectedGame('youxi', event, {setGameName})}>端游</p>
                          </div>) : null
                      }

                    </div>
                  </div>
                  <div className="kf_dialog-list-select-item">
                    <span>游戏名称：</span>
                    <input value={gameName} placeholder={'请输入游戏名称'} onBlur={() => {
                      initshowGame({setShowGame})
                    }} onChange={(e) => {
                      // setGameName(e?.target?.value);
                      changeName(e, {
                        setGameName,
                        setMatching,
                        setArr,
                        setShowGame
                      })
                    }} type='text'/>
                    {
                      showGame ?
                        (<div className="kf_dialog-list-select-auto">
                          {arr.map((item, k) => <div onClick={() => GameSend(item, {setGameName, setShowGame})}
                                                     key={k}>{item}</div>)}
                        </div>) : null
                    }
                  </div>
                  {!showGame && gameName && !arr.length ? (
                    <div
                      className="kf_dialog-list-select-auto-tip">请输入正确的游戏名称，并在下拉框中选择</div>) : null}
                </div>
              </div>
            ) : null}
          </div>
        )}
        {
          ownCollapse ? <div className={styles['collapse-btn']} onClick={() => {
            setOwnCollapse(false)
          }}>
            <span>{t('show-more')}</span>
            <svg className={`icon `} aria-hidden="true">
              <use xlinkHref={`#icon-zhike-arrow-down-black`}/>
            </svg>
          </div> : null
        }
      </div>
    </>
  }


  const render = () => {
    if (type === 'weakText') {
      return renderWeakText();
    }
    // console.log();
    let extContent = convertExtContent(contentList);
    // if(!(Array.isArray(extContent) && extContent.length > 0)){
    extContent = [content, ...extContent];
    // }

    return <div
      className={`${type === 'left' ? styles[className] : `${styles[className]} ${styles.u_dialog}`} ${collapse ? styles['collapse_dialog'] : ''}`}>
      {type === 'left' ? (
        <Fragment>
          <img className="kf_dialog-pic"
               src={info.kfInfo && info.kfInfo.url || "https://p3.ssl.qhimg.com/t010a6232b25737b10d.png"}/>
          <div
            className="kf_dialog-name">{speakerName || (imInfo && imInfo.nickName) || (info.kfInfo && info.kfInfo.nickName)} {msgTime}</div>
        </Fragment>
      ) : (
        <Fragment>
          <img className="kf_dialog-pic"
               src={info.uInfo && info.uInfo.url || "https://p0.ssl.qhimg.com/t01d63eafe7af7c2615.png"}/>
          <div className="kf_dialog-name">{msgTime} {info.uInfo && info.uInfo.name || t('tourist')}</div>
        </Fragment>
      )}
      {/* 内容 */}
      <div style={{
        maxWidth: '100%',
        position: 'relative',
        display: 'inline-block',
        marginBottom: (type === 'left' && (showIMBtn)) ? '22px' : ''
      }}>

        {
          (extContent).map((item, index) => {
            return <RenderContent key={index} {...props} content={item}/>
          })
        }

        {/*<div ref={textRef} className="kf_dialog-content"*/}
        {/*>*/}
        {/*  {*/}
        {/*    collapse ? <div className={styles['collapse-btn']} onClick={() => {*/}
        {/*      setCollapse(false)*/}
        {/*    }}>*/}
        {/*      <span>{t('show-more')}</span>*/}
        {/*      <svg className={`icon `} aria-hidden="true">*/}
        {/*        <use xlinkHref={`#icon-zhike-arrow-down-black`}/>*/}
        {/*      </svg>*/}
        {/*    </div> : null*/}
        {/*  }*/}

        {/*  {!showMore && list.length == 0 ? (*/}
        {/*    <Fragment>*/}
        {/*      {content ? <>*/}
        {/*        {innerHtml || true ?*/}
        {/*          (<div onClick={dialogClickHandle} className={`kf_dialog-text*/}
        {/*         ${chatType === 3 ? styles['leave-header'] : ''}*/}
        {/*         ${chatType === 4 ? styles['unusual-leave-header'] : ''}*/}
        {/*         `}*/}
        {/*                dangerouslySetInnerHTML={{__html: convertHtml(content, aTarget)}}></div>) :*/}
        {/*          (<div className="kf_dialog-text">{content}</div>)}*/}
        {/*      </> : null}*/}

        {/*      {Component ? <div*/}
        {/*        className={`*/}
        {/*      ${chatType === 3 ? `${styles['leave-header']} ${styles['-component']}` : ''}*/}
        {/*      ${chatType === 4 ? `${styles['unusual-leave-header']} ${styles['-component']}` : ''}*/}
        {/*       `}*/}
        {/*      >*/}
        {/*        <Component {...props} />*/}
        {/*      </div> : null}*/}

        {/*      /!* 目前仅发送消息增加状态 *!/*/}
        {/*      {type === 'right' && loading && loading === 'loading' ?*/}
        {/*        <div className={`${styles['dialog_loading']} ${loading === 'loading' ? styles['-rotate'] : ''}`}>*/}
        {/*          <img*/}
        {/*            src={`${loading === 'loading' ? 'https://p2.ssl.qhimg.com/t016dd17b0c93618fad.png' : (loading === 'error' ? 'https://p5.ssl.qhimg.com/t01456d9a80c2eb0517.png' : '')}`}/>*/}
        {/*        </div> : null}*/}


        {/*      {type === 'right' && loading && loading === 'error' ?*/}
        {/*        <div className={`${styles['dialog_loading']} ${styles['-error']} `}>*/}
        {/*          <img*/}
        {/*            src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAROSURBVHgB7ZvNbhNXFMf/5xqppBtStcuqOE9AwgvUtFI3rUTKsmqCQaJY3UC76wrKC4C6IZRFbGDRnbOgXSC1OBJS2SDyBnYQe8ImCcgzhzMztjJfHs/Ycy+ewT/J8cx4nDvn73O/zj0XmDPng4ZgCK43qlLcMmz7JGxakpJPBG/AayjuQqldoN+j5t0dGECbAFy/ugj74DxY1UB2TYpaRCZ4T77bkSfcEmG2qbnRgwZyF0B+6RosXJP/XEOeMJqooCVCdJAjuQmgzfAoPXns63Tvdgs5MLUAbt22sGnA8FDBtIUK/zJt1VCYAl6/fAU2PzduvAPxKmx0eb1xDVMwkQe4DZx12H4vhsfhesNHF6h5aw8ZySyA6/I2HsthFbNFT/z5TNYqkUmAGTZ+SGYRUgtQAOOHZBIhlQDeoObwOWbf+CEiwvGVNG1Cul7AOthEcYx3kK75zWaaG8cK4HYzRKsoGtJN8tpPV8felvThoN53UVhkPqFoJak9SPYAi2+i0MgEzBmlJjBSAF5r1LW7/hefey+dyGCNf5R5ygiOJXxxqiHmWFa/A77/1jveegi0/4Y2PFs6cR/FeoA7s9Pd6g+Nd/jma2glwQviq0AfdZjk4wVoZ4RHRwRwW36F8ygbjhc4A7oQUQ+wZmSGpwNrvx6+FFMF6CzKCqla+FJUADeAWVb4y/CVgABc/3k5e/S2SNCiF54/IugB/X4VZcfmU/7ToABUqBnfZDAv+U9DAlAVZYdU1X8aagTLXP8HhJbkpgqLl4G5APjACQnAmRcWJmZ/33d8AGM4y/A+ggIw92CK9j/euyNE+yGMwXbPfxoMiLCEk02lTDz6F3jyv3fs9wbdEAVinEEBjqkdCYKaw6ThQ5S96z+N/N6y4vuqxOOBPbq38Yn/QrQXcNJSyst2+EJMUNSSm5T+hRAnGnxpEHi62wJevIR+qB2+EvWAytsmTHClcRQW/+1XGEFxxAMiAlCzuSe9QQe6+ezTo2MzQdFm3ApR/EiQ8TvKhoXYpKpYAejBRke7Fzzz5UE++g+a6bo2xTB6ZcjxAp05QH/cAU6f8tz/yVPohUZ6dPLq8Frj8cwkQk0KU5vu3z436uPk2WAFF4xOkPKHUeHELiZRALfVZFXkBvHGuFyhdDlCa5fbhcsSIW5T6865cbelC4hU3khVcHJ0C0MXtHAxzY2pBHAHRwpnUAwRuvKsX6XNGi1bouTAeA2JkkNmWITMxjtkDoq6BajDFQmfbWE2YKfBgzp+epLU+akCYLzeuC5venOJknklMb4bdP/PW5iQfDZMMN0UjzDZTbI7V6ng4rQbJvLbMuOm1bneUIU+PMNZBjgP8tk7lP+mKScbS7lJVnnlGfHgb66GD9G4bc7dS1STo7PehCpToJW9bXNwts1tQy20JtkNkgZTqwDgHy4tQ1FVXiel2CUx7kToSWTFhruweRd9tUN/6dknOGfOnADvABTNa75R6fySAAAAAElFTkSuQmCC`}/>*/}
        {/*          <span>消息发送失败</span>*/}
        {/*        </div> : null}*/}
        {/*    </Fragment>*/}
        {/*  ) : (*/}
        {/*    <div className="kf_dialog-text kf_dialog-list">*/}
        {/*      {*/}
        {/*        content ?*/}
        {/*          <div className="kf_dialog-list-title"*/}
        {/*               onClick={dialogClickHandle}*/}
        {/*               dangerouslySetInnerHTML={{__html: convertHtml(content, aTarget)}}></div> : null*/}
        {/*      }*/}

        {/*      {list.map((item, k) => <div className="kf_dialog-list-content" key={k}*/}
        {/*                                  dangerouslySetInnerHTML={{__html: (typeof item === 'string' ? item : item?.question)}}*/}
        {/*                                  onClick={() => {*/}
        {/*                                    radarLog({c: 'blo_send'});*/}
        {/*                                    triggerSend(typeof item === 'string' ? item : item?.question, item.question_id || item.faq_data_id, {*/}
        {/*                                      msgId,*/}
        {/*                                      record: item*/}
        {/*                                    })*/}
        {/*                                  }}></div>)}*/}
        {/*      {!list.length ? (*/}
        {/*        <div className="kf_dialog-list-box">*/}
        {/*          {*/}
        {/*            gameList.length != 0 ? (*/}
        {/*              <Fragment>*/}
        {/*                <div className="kf_dialog-list-list">*/}
        {/*                  {gameList.map((item, k) => <div className="kf_dialog-list-item" key={k}*/}
        {/*                                                  onClick={() => triggerGameSend(item)}>{item}</div>)}*/}
        {/*                </div>*/}
        {/*              </Fragment>*/}
        {/*            ) : null*/}
        {/*          }*/}
        {/*          <div className="kf_dialog-list-select">*/}
        {/*            <p className="kf_dialog-list-select-title">*/}
        {/*              {gameList.length === 0 ? '请选择您要咨询的游戏：' : '咨询其他游戏请选择：'}*/}
        {/*            </p>*/}
        {/*            <div className="kf_dialog-list-select-item">*/}
        {/*              <span>游戏平台：</span>*/}
        {/*              <div className="kf_dialog-list-select-box">*/}
        {/*                <span className={obj[gamePlatform] ? "kf_dialog-list-select-box-active" : ""} onClick={() => {*/}
        {/*                  selectedItem()*/}
        {/*                }}>{obj[gamePlatform] ? obj[gamePlatform] : '请选择游戏平台'}</span>*/}
        {/*                {*/}
        {/*                  showSelect ? (*/}
        {/*                    <div className="select-list">*/}
        {/*                      <p onClick={() => selectedGame('mobile', event)}>手游</p>*/}
        {/*                      <p onClick={() => selectedGame('wan', event)}>页游</p>*/}
        {/*                      <p onClick={() => selectedGame('youxi', event)}>端游</p>*/}
        {/*                    </div>) : null*/}
        {/*                }*/}

        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="kf_dialog-list-select-item">*/}
        {/*              <span>游戏名称：</span>*/}
        {/*              <input value={gameName} onBlur={() => {*/}
        {/*                initshowGame()*/}
        {/*              }} onChange={() => {*/}
        {/*                changeName(event)*/}
        {/*              }} type='text'/>*/}
        {/*              {*/}
        {/*                showGame ?*/}
        {/*                  (<div className="kf_dialog-list-select-auto">*/}
        {/*                    {arr.map((item, k) => <div onClick={() => GameSend(item)} key={k}>{item}</div>)}*/}
        {/*                  </div>) : null*/}
        {/*              }*/}
        {/*            </div>*/}
        {/*            {!showGame && gameName && !arr.length ? (*/}
        {/*              <div*/}
        {/*                className="kf_dialog-list-select-auto-tip">请输入正确的游戏名称，并在下拉框中选择</div>) : null}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      ) : null}*/}
        {/*    </div>*/}
        {/*  )}*/}

        {
          stopQuestion || answerType > 5 ? <div style={{    width: '100%',
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            gap: '8px',
            marginTop: 4}}>
            {answerType > 5 ?<span style={{background:'#fff',padding:'2px 6px',borderRadius:'3px',fontSize:'12px',lineHeight:'1.25',color:'rgb(150,150,150)'}}>内容由AI生成，仅供参考</span>:null}
            {stopQuestion ?<span style={{background:'#fff',padding:'2px 6px',borderRadius:'3px',fontSize:'12px',lineHeight:'1.25',color:'rgb(150,150,150)'}}>已停止回答</span>:null}
          </div>:null
        }

        {/*</div>*/}
        {disable ? <div className={styles['shadow-container']}></div> : null}
        {/* 点赞 */}
        {type === 'left' && showFeedback && id && (
          <div className="kf_dialog-feedback">
            <img src="https://p4.ssl.qhimg.com/t01c4b160e7b39ce7f7.png" onClick={(e) => feedbackClick(e, 1)}/>
            <img src="https://p3.ssl.qhimg.com/t017c856b6e6b3fd5a5.png" onClick={(e) => feedbackClick(e, 0)}/>
          </div>
        )}

        {/*转人工按钮*/}
        {
          type === 'left' && (showIMBtn) && (
            <div className="kf_dialog-im" onClick={() => {
              connectIM(false, prompt, null, 1);
              radarLog({c: 'zd_transfer'});
            }}>
              转人工
            </div>
          )
        }
      </div>

    </div>
  }

  const className = isMobile ? 'mkf_dialog' : 'kf_dialog'
  return <InView onChange={(inView, entry) => {return;console.log("Inview:", inView,content,msgId)}}>
    {(
      isMobile ? renderMobile() : render()
    )}
  </InView>

}

export default React.memo(Dialog, (prev, next) => {
  // console.log(prev,next);
  if (
    prev.content === next.content &&
    prev.loading === next.loading &&
    prev.disable === next.disable &&
    prev.showIMBtn === next.showIMBtn &&
    prev.isIMConnect === next.isIMConnect &&
    prev.id === next.id &&
    prev.stopQuestion === next.stopQuestion &&
    prev.answerType === next.answerType &&
    prev.info === next.info
  ) {
    return true;
  } else {
    return false;
  }
});
;
