const openIdKey = 'wx_open_id';
const wxMark = 'wx_mark';
const queryString = require('query-string');
const rid = queryString.parse(location.search).rid;

const sidKey = '_zk_init_sid';

const muteKey = '_zk_mute';

const muteStatus = (mute) => {
  try {
    if (window.localStorage) {
      // debugger;
      if (mute === undefined) {
        let str = window.localStorage.getItem(muteKey);
        str = (str === 'true' || str === true) ? true : false;
        // console.log(str, typeof str);
        return !!str;
      } else {
        window.localStorage.setItem(muteKey, mute);
        return mute;
      }
    }
  } catch (e) {
  }
  return false;
}

// 设定访问令牌
const setWXOpenID = token => {

  try {
    let str = '';
    if (window.sessionStorage) {
      str = window.sessionStorage.getItem(openIdKey);
    }
    // alert(JSON.stringify(str));
    if (!str || str === '') {
      str = '{}';
    }
    let obj = {};
    try {
      obj = JSON.parse(str);
    } catch (e) {
      obj = {};
    }

    if (typeof obj != 'object' || Array.isArray(obj)) {
      obj = {};
    }
    obj[rid] = token;
    window.sessionStorage && window.sessionStorage.setItem(openIdKey, JSON.stringify(obj));
  } catch (e) {

  }


};
// 获取访问令牌
const getWXOpenID = () => {
  try {
    let token;
    if (window.sessionStorage) {
      token = window.sessionStorage.getItem(openIdKey);
    }
    if (!token || token === '') {
      return null;
    }
    let obj = JSON.parse(token) || {};
    if (typeof obj != 'object' || Array.isArray(obj)) {
      return null;
    }
    return obj[rid];
  } catch (e) {

  }

}
// 清空访问令牌
const clearWXOpenID = () => {
  try {
    window.sessionStorage && window.sessionStorage.removeItem(openIdKey);
  } catch (e) {

  }

}

// 设定sid
const setSid = sid => {
  try {
    window.localStorage && window.localStorage.setItem(sidKey, sid);
  } catch (e) {

  }


};
// 获取sid
const getSid = () => {
  try {
    let sid;
    if (window.localStorage) {
      sid = window.localStorage.getItem(sidKey);
    }
    if (!sid || sid === '') {
      return null;
    }
    return sid;
  } catch (e) {

  }

}

const setWXMark = token => {
  try {
    window.localStorage && window.localStorage.setItem(wxMark, JSON.stringify(token));
  } catch (e) {

  }

};

const getWXMark = () => {
  try {
    let token;
    if (window.localStorage) {
      token = window.localStorage.getItem(wxMark);
    }
    if (!token || token === '') {
      return null;
    }
    return JSON.parse(token);
  } catch (e) {

  }

};

const hasCollectedClues = (wid, flag = false) => {

  try {
    const setValue = value => {
      flag ? window.localStorage.setItem(`c-c-data`, JSON.stringify(value)) : void (0);
    }
    if (window.localStorage) {
      let localData = window.localStorage.getItem(`c-c-data`);
      if (!localData) {
        localData = {};
        localData[wid] = true;
        setValue(localData);
        return false;
      } else {
        try {
          let item = JSON.parse(localData);
          if (item && item[wid]) {
            return true;
          } else {
            item = item || {};
            item[wid] = true;
            setValue(item);
            return false;
          }

        } catch (e) {
          localData = {};
          localData[wid] = true;
          setValue(localData);
          return false
        }
      }
      return false;
    }
  } catch (e) {
    return false;
  }
}

const getObjFromName = ()=>{
  let obj = {};
  try{
    obj = JSON.parse(window.name);
  }catch (e){
  }
  return obj;
}

const setItemForName = (key, value) => {
  let obj = getObjFromName();
  try{
    obj[key] = value;
    window.name = JSON.stringify(obj);
  }catch (e){
  }
}

const getItemFormName = (key)=>{
  let obj = getObjFromName();
  return obj[key];
}

export {
  setWXOpenID,
  getWXOpenID,
  clearWXOpenID,
  setWXMark,
  getWXMark,
  hasCollectedClues,
  getSid,
  setSid,
  muteStatus,
  setItemForName,
  getItemFormName
}