import React, {useState, useEffect, useRef} from 'react';
import styles from './index.less';

;

export default function LeaveMessage(props) {
  const {
    info,
    leaveContent = "",
    isMobile = false,
    showImLeftMessage = false,
    setShowImLeftMessage = () => {
    },
    submitIMLeaveMessage = () => {
    },
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [firstInPage, setFirstInPage] = useState(false);
  useEffect(() => {
    if (showImLeftMessage) {
      setFirstInPage(true);
    } else {
      setInputValue("");
    }
  }, [showImLeftMessage]);

  const closeContainer = () => {
    setInputValue("");
    setShowImLeftMessage(false);

  };
  const onInputDataChange = e => {
    setInputValue(e.target.value);
  };
  const onSubmitClick = () => {
    submitIMLeaveMessage(inputValue);
  };

  const renderMobile = () => {
    return <div>
      {firstInPage ? <div>
        <div
          className={`${styles['mobile-shade-container']} ${showImLeftMessage ? styles['-mobile-active'] : styles['-mobile-hidden']} `}>
        </div>

        <div className={`${styles['mobile-message']} ${showImLeftMessage ? styles['-mobile-active'] : styles['-mobile-hidden']} `}>
          <div className={styles['close-div']}>
            <svg className={styles['close-btn']} aria-hidden="true" onClick={closeContainer}>
              <use xlinkHref={`#icon-zhike-feedback-close`}/>
            </svg>
          </div>

          <div className={styles['mobile-title']}>
            <span>留言</span>
          </div>

          <div className={styles['mobile-tip-div']}>
            <span className={styles['mobile-tip']}>{leaveContent}</span>
          </div>

          <textarea
            placeholder={'留言内容'}
            className={styles['mobile-input']}
            value={inputValue}
            onChange={onInputDataChange}
          />
          <div className={styles['mobile-submit-btn']} onClick={onSubmitClick}>
             提交
          </div>

        </div>

      </div> : null}

    </div>
  };

  const render = () => {
    return <div>
      {firstInPage ?
        <div className={styles['pc-all-container']}>
          <div
            className={`${styles['shade-container']} ${showImLeftMessage ? styles['-active'] : styles['-hidden']} `}></div>
          <div className={`${styles['pc-container']} ${showImLeftMessage ? styles['-active'] : styles['-hidden']} `}>

            <div className={styles['pc-wrapper']}>
              <div className={styles['pc-title']} style={{background: info.btnColor}}>
                <div><span className={styles['title-word']}>留言</span></div>
                <div><a className={styles['title-word']} onClick={closeContainer}>关闭</a></div>
              </div>
              <span className={styles['message-tips']}>{leaveContent}</span>
              <div className={styles['pc-content']}>
                <div style={{ marginTop: 20, width: 80}}>
                  <span className={styles['message-title']}> 留言内容</span>
                </div>
                <textarea className={styles['input-content']} value={inputValue} onChange={onInputDataChange}/>
              </div>
              <div className={styles['submit-btn']} style={{background: info.btnColor}} onClick={onSubmitClick}><span
                className={styles['submit-word']}>提交</span></div>
            </div>


          </div>

        </div>
        : null}
    </div>
  };
  return (
    isMobile ? renderMobile() : render()
  )
}