import React, {useState, useEffect, useImperativeHandle} from 'react';
import styles from './index.less';
import Dialog from '../Dialog';
import {useTranslation} from "react-i18next";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
let debounceTimeout;
const Center = React.forwardRef((props, ref) => {
  const {
    haveMore,
    noticeContent,
    zkHaveMore,
    active,
    clearActive = () => {

    },
    learnMore = () => {

    },
    zkLearnMore = () => {

    },
    list,
    isMobile = false,
    info = {},
    triggerSend = () => {
    },
    triggerGameSend = () => {
    },
    setNewInformationNumber = () => {

    },
    setShowNewInformationTips = () => {

    },
    showNewInformationTips = false,
    newInformationNumber,
    showMoreFun = false,
    hideHeader,
    isGameRobot = false,
    canCollect = () => {
    },
    postMessage,
    setCurrentAutoIMItemID,
    countResponse,
    connectIM = () => {
    },
    closeTyped = ()=>{

    },
    msgRetry = ()=>{

    },
    isIMConnect,
  } = props
  let [centerEl, setCenterEl] = useState('');

  let [scrollHeight, setScrollHeight] = useState(0);

  const [lightUrl, setLightUrl] = useState('');
  let [imageList, setImageList] = useState([]);
  let [imageIndex, setImageIndex] = useState(-1);
  const [listLength, setListLength] = useState(0);
  const {t} = useTranslation();

  function getBase64(imgUrl, deg) {
    let Img = new Image();
    let dataURL = '';
    Img.src = imgUrl;
    Img.setAttribute('crossorigin', 'anonymous');
    Img.onload = function () {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let width = Img.width;
      let height = Img.height;
      let w = deg % 180 === 0 ? width : height;
      let h = deg % 180 === 0 ? height : width;
      let obj = {
        x: w / 2,
        y: h / 2,
        w: width,
        h: height,
      };
      canvas.width = w;
      canvas.height = h;
      ctx.translate(obj.x, obj.y);
      ctx.rotate((deg * Math.PI) / 180);
      ctx.translate(-obj.x, -obj.y);
      ctx.drawImage(Img, obj.x - obj.w / 2, obj.y - obj.h / 2, obj.w, obj.h);
      dataURL = canvas.toDataURL();
      setLightUrl(dataURL);
    };
  }

  const onPrev = () => {
    let index = imageIndex;
    if (index === 0) {
      setLightUrl(imageList[imageList.length - 1]);
      setImageIndex(imageList.length - 1);
    } else {
      setLightUrl(imageList[index - 1]);
      setImageIndex(index - 1);
    }
  };
  const onNext = () => {
    let index = imageIndex;
    if (index + 1 === imageList.length) {
      setLightUrl(imageList[0]);
      setImageIndex(0);
    } else {
      setLightUrl(imageList[index + 1]);
      setImageIndex(index + 1);
    }
  };
  const onClose = () => {
    setLightUrl('');
    setImageIndex(-1);
  };

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      scrollToBottom(300);
    },
  }));

  function scrollToBottom(time) {
    clearTimeout(debounceTimeout);
    if (!centerEl) {
      return
    }
    const scroll = ()=>{

    }
    if(time === 0){
      const Height = centerEl.scrollHeight // 真实高度
      const ShowHeight = centerEl.clientHeight // 可见高度
      const MaxScrollTop = Height - ShowHeight
      $(centerEl).animate({
        scrollTop: MaxScrollTop > 0 ? MaxScrollTop : 0
      }, 0)
      return;
    }
    debounceTimeout = setTimeout(function () {
      const Height = centerEl.scrollHeight // 真实高度
      const ShowHeight = centerEl.clientHeight // 可见高度
      const MaxScrollTop = Height - ShowHeight
      $(centerEl).animate({
        scrollTop: MaxScrollTop > 0 ? MaxScrollTop : 0
      }, time != undefined ? time : (isMobile ? 250 : 300))
    }, 200);
    // centerEl.scrollTop = MaxScrollTop > 0 ? MaxScrollTop : 0
  }

  useEffect(() => {
    if (centerEl) {
      setTimeout(()=>{
        //加载历史数据 并保持滚动位置
        if (active === 'load') {
          centerEl.scrollTop = centerEl.scrollTop + (centerEl.scrollHeight - scrollHeight - 14);
        } else if(active === 'init-load'){
          scrollToBottom(100);
        } else { //默认正常对话 滚动到底
          setTimeout(() => {
            scrollToBottom();
          }, 400);
        }

        setScrollHeight(centerEl.scrollHeight );//记录高度
        clearActive();//清空active
      });
    }

  }, [list,centerEl]);

  useEffect(() => {
    if (centerEl) {
      if (list && list.length > 0) {
        setListLength(list?.length);
        let imgReg = /<img.*?(?:>|\/>)/gi;
        let imgArr = [];
        list.map(value => {
          try{
            let arr = value.content && value.content.match && value.content.match(imgReg);
            if (Array.isArray(arr) && arr.length > 0) {
              for (let i = 0; i < arr.length; i++) {
                const d = document.createElement('div');
                d.innerHTML = arr[i];
                let src = d.children[0].getAttribute('src');
                let img = new Image();
                img.src = src;
                img.onload = function () {
                  imgArr.push(src);
                };
              }
            }
          }catch (e){
          }
        });

        setImageList(imgArr);
      }
      //加载历史数据 并保持滚动位置
      if (active === 'load') {
        // centerEl.scrollTop = centerEl.scrollTop + (centerEl.scrollHeight - scrollHeight);
      } else if (scrollHeight - centerEl.scrollTop - centerEl.clientHeight > 100
        && list[list.length - 1]?.type === "left"
      ) {
        setNewInformationNumber(pre => {
          if (listLength > list.length || listLength === list.length) {
            return pre;
          } else {
            return pre + 1;
          }
        });
        setShowNewInformationTips(true);
      } else if (listLength < list.length || listLength === 0) { //默认正常对话 滚动到底
        setTimeout(() => {
          // scrollToBottom();
          setNewInformationNumber(0);
          setShowNewInformationTips(false);
        }, 400);
      }
      // setTimeout(() => {
      //   setScrollHeight(centerEl.scrollHeight); //记录高度
      // }, 400);
      // clearActive();//清空active


    }
  }, [list, centerEl]);


  useEffect(() => {
    if (!showNewInformationTips && newInformationNumber === 0) {
      scrollToBottom();
    }
  }, [showNewInformationTips]);

  const scrollHandle = e => {
    const scrollTop = centerEl.scrollTop;
    const clientHeight = centerEl.clientHeight;
    const scrollHeight = centerEl.scrollHeight;
    const isBottom = scrollTop + clientHeight === scrollHeight;
    if (isBottom) {
      setShowNewInformationTips(false);
      setNewInformationNumber(0);
    }
  };
  const getNowTime = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    if (minute < 10) {
      minute = '0' + minute;
    }
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (second < 10) {
      second = '0' + second;
    }
    let time =
      year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    return time;
  };
  // useEffect(()=>{
  //   if(centerEl && centerEl.scrollHeight - centerEl.scrollTop - centerEl.clientHeight === 0){
  //     setShowNewInformationTips(false);
  //     setNewInformationNumber(0);
  //   }
  // },[centerEl.scrollTop]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     scrollToBottom(0);
  //   }, 500);
  // }, [centerEl]);

  return (

    <div
      className={`${isMobile ? styles.mkf_content : styles.kf_content} 
      ${noticeContent?styles['notice-content']:''}
      ${hideHeader && isMobile ? styles.hide_header_content : ''} 
      ${isMobile && showMoreFun ? styles['more-fun-show'] : ''} 
       ${isGameRobot || canCollect() ? styles['game_robot'] : ''}`}
      onScrollCapture={el => {
        scrollHandle(el)
      }}
      ref={el => setCenterEl(el)}>

      {isMobile && (info.kfInfo && info.kfInfo.robot_im_sobot) && haveMore ?
        <div className={styles['load-more-btn']} onClick={() => {
          active != 'load-loading' ? learnMore() : void (0)
        }}>
          {active === 'load-loading' ? <img src="https://p2.ssl.qhimg.com/t016dd17b0c93618fad.png"/> :
            <span>{t('load-more')}</span>}
        </div> : null}

      {zkHaveMore && (info?.kfInfo?.customer_mode !== 1 && !info.kfInfo.robot_im_sobot) ?
        <div className={styles['load-more-btn']} onClick={() => {
          active != 'load-loading' ? zkLearnMore() : void (0)
        }}>
          {active === 'load-loading' ? <img src="https://p2.ssl.qhimg.com/t016dd17b0c93618fad.png"/> :
            <span>{t('load-more')}</span>}
        </div> : null}

      {list.length !== 0 && list.map((dialog, index) => {
        if (!dialog.msgTime || dialog.msgTime === "") {
          dialog.msgTime = getNowTime();
        }
        return <Dialog postMessage={postMessage} setLightUrl={setLightUrl} {...dialog} key={dialog?._id || dialog?.msgId || index} info={info}
                       isMobile={isMobile}
                       msgRetry={msgRetry}
                       isIMConnect={isIMConnect}
                       origin={dialog}
                       closeTyped={closeTyped}
                       setImageIndex={setImageIndex}
                       imageList={imageList}
                       connectIM={connectIM}
                       triggerSend={triggerSend}
                       setCurrentAutoIMItemID={setCurrentAutoIMItemID}
                       countResponse={countResponse}
                       triggerGameSend={triggerGameSend} scrollToBottom={scrollToBottom}/>
      })}

      {lightUrl ? <Lightbox
        mainSrc={lightUrl}
        enableZoom={true}
        zoomInLabel
        zoomOutLabel
        prevSrc={
          imageList[
          (imageIndex + imageList.length - 1) % imageList.length
            ]
        }
        nextSrc={imageList[(imageIndex + 1) % imageList.length]}
        toolbarButtons={[
          <div className={isMobile ? styles['mnumber-box'] : styles['number-box']}>
            {imageIndex + 1} / {imageList.length}
          </div>,
          <div className={isMobile ? styles['mtransfer-box'] : styles['transfer-box']}>
            <svg
              className={isMobile ? styles['mtransfer-icon'] : styles['transfer-icon']}
              onClick={() => {
                getBase64(lightUrl, -90);
              }}
              aria-hidden="true"
            >
              <use xlinkHref={`#icon-zhike-Icon_xiangzuofanzhuan`}/>
            </svg>
          </div>,
          <div className={isMobile ? styles['mtransfer-box'] : styles['transfer-box']}>
            <svg
              className={isMobile ? styles['mtransfer-icon'] : styles['transfer-icon']}
              onClick={() => {
                getBase64(lightUrl, 90);
              }}
              aria-hidden="true"
            >
              <use xlinkHref={`#icon-zhike-Icon_xiangyoufanzhuan`}/>
            </svg>
          </div>,
        ]}
        onImageLoadError={{
          imageSrc:
            'https://p2.ssl.qhimg.com/t017638ac82012a6f59.png',
        }}
        onCloseRequest={onClose}
        onMovePrevRequest={onPrev}
        onMoveNextRequest={onNext}
      /> : null}
    </div>


  )
})
export default (Center);