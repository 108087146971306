import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './index.less';

const batchSize = 5;
const H5Card = (props) => {
  const {
    componentExtra = {},
    triggerSend = () => {

    },
    msgId
  } = props;
  const formRef = useRef(null);

  const { common_message } = componentExtra;

  const setCommonsAndGroups = (common) => {
    setActiveCommon(common);
    setSizeIndex(0);
    if (Array.isArray(common?.common_groups) && common?.common_groups.length > 0) {
      setActiveGroup(common?.common_groups[0]);
    } else {
      setActiveGroup(null);
    }
  }

  useEffect(() => {
    if (Array.isArray(componentExtra?.commons) && componentExtra?.commons.length > 0) {
      setCommons(componentExtra?.commons);
      setCommonsAndGroups(componentExtra?.commons[0])
    }
  }, [componentExtra]);

  const [commons, setCommons] = useState([]);
  const [activeCommon, setActiveCommon] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [sizeIndex, setSizeIndex] = useState(0);
  const tabsRef = useRef(null);

  const changeTabs = () => {
    tabsRef.current.scrollLeft = 0;
  }

  return <div className={styles['container']}>
    {commons && commons.length > 1 && <div className={styles['product_container']}>
      <div className={styles['items-container']}>
        <div className={styles['items']}>
          {
            commons && commons.map(common => {
              return <div className={` ${common?.record_id === activeCommon?.record_id ? styles['wrap'] : ''}`}
                key={common.record_id}>
                <div
                  onClick={() => {
                    setCommonsAndGroups(common);
                    setSizeIndex(0);
                    changeTabs();
                  }}
                  key={common.record_id}
                  className={`${styles['product_item']} ${common?.record_id === activeCommon?.record_id ? styles['-active'] : ''}`}
                >
                  {common?.icon &&
                    <div style={{ backgroundImage: `url(${common?.icon})` }} className={styles['icon']}></div>
                  }
                  <div className={styles['desc']}>{common.name}</div>
                </div>
              </div>
            })
          }
        </div>
      </div>

    </div>}
    <div className={styles['card-container']}>
      {componentExtra?.commons_img &&
        <div style={{ backgroundImage: `url(${componentExtra?.commons_img})` }} className={styles['side-view']}></div>
      }
      <div className={styles['content']}>
        <div className={styles['content-font']}>
          {activeCommon && activeCommon?.common_groups && (activeCommon?.common_groups.length > 1 || activeCommon?.common_groups[0]?.name) ?
            <div className={styles['header-container']}>
              <div className={styles['tabs-container']}>
                <div className={styles['tabs']} ref={tabsRef} >
                  {
                    activeCommon && activeCommon?.common_groups && activeCommon?.common_groups.map(group => {
                      return <div key={group.record_id}
                        onClick={() => {
                          setActiveGroup(group)
                        }}
                        className={`${styles['tab']} ${group?.record_id === activeGroup?.record_id ? styles['-active'] : ''}`}>
                        {group?.name}
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
            :
            <div className={styles['guide-container']}>
              {/* <img src={'https://p0.qhimg.com/t11098f6bcdb373720a77284301.png'} /> */}
              <span>{common_message}</span>
            </div>
          }
          <div className={styles['list-container']}>
            {
              activeGroup && activeGroup.commons &&
              Array.from({ length: activeGroup.commons.length > batchSize ? batchSize : activeGroup.commons.length }, (_, i) => activeGroup.commons[(sizeIndex + i) % activeGroup.commons.length]).map(common => {
                return <div key={common.question_id} className={styles['list-item']} onClick={() => {
                  triggerSend(common.name || common?.question, common.question_id || common.record_id || common.faq_data_id, {
                    msgId,
                    record: common
                  })
                }}>
                  <div className={styles['desc']}>{common.name || common?.question}</div>
                  <svg className={`icon ${styles['icon']}`} aria-hidden="true">
                    <use xlinkHref={`#icon-zhike-bianzu2`} />
                  </svg>
                </div>
              })
            }
          </div>
        </div>
        {activeGroup && activeGroup.commons && activeGroup && activeGroup.commons.length > 5 &&
          <div
            onClick={() => { setSizeIndex(sizeIndex + batchSize) }}
            className={styles['change-btn']}
          >
            <span>换一换</span>
            <i style={{ fontSize: '.12rem' }} className="icon-zhike icon-zhike-Refresh1"></i>
          </div>}
      </div>
    </div>
  </div>
}

export default H5Card;