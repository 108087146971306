import PulseLoader from "react-spinners/PulseLoader";
import React from "react";

const LoadingComponent = props => {
  return <div style={{padding: '8px 12px', paddingTop: '12px'}}>
    <PulseLoader size={8} speedMultiplier={0.6} color={"#666666"}/>
    {/*<img style={{width:120,height:120}} src = "https://p0.qhimg.com/t012c0aea66780a9749.gif" />*/}
    {/*<Spin*/}
    {/*    indicator={*/}
    {/*      <LoadingOutlined*/}
    {/*          style={{*/}
    {/*            fontSize: 18,*/}
    {/*          }}*/}
    {/*          spin*/}
    {/*      />*/}
    {/*    }*/}
    {/*/>*/}
  </div>
}

export default LoadingComponent;