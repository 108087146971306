import React, {useState, useEffect, useRef} from 'react';
import styles from './index.less';
import {toast} from "../../static/util/utils";
import { Input } from 'antd';
import radarLog from "../../static/util/radar";
const { TextArea } = Input;
const tostTip = toast();

export default function IMFeedback(props) {
  const {
    info,
    isMobile = false,
    showFeedback = false,
    setShowFeedback = () => {

    },
    submitFeedback = () => {

    },
    feedbackInitiative = true,
    config = {}
  } = props;

  const [likeState, setLikeState] = useState('1');
  const [starScore, setStarScore] = useState(5);
  const [text, setText] = useState('');
  const [sortSettings, setSortSettings] = useState([]);
  const [selectedLabelIndex, setSelectedLabelIndex] = useState([]);

  useEffect(() => {

    if (config && Array.isArray(config.star_setting)) {
      let temp = config.star_setting.sort((a, b) => a.level - b.level);
      setSortSettings(temp)
    } else {
      setSortSettings([]);
    }

    if (config && config.star_display === 1) {
      setStarScore(5);
    } else if (config && config.star_display === 2) {
      setStarScore(0);
    }

    return () => {
      setSortSettings([]);
    }
  }, [config]);


  const feedbackClick = value => {
    setLikeState(value);
  }

  const closeFeedback = () => {
    setShowFeedback(false);
    submitHandle('close');
  };

  const canSubmit = () => {
    if (likeState != '' && starScore > 0) {
      return true;
    }
    return false;
  }


  function handleChange(e) {
    setText(e.target.value)
  }

  const submitHandle = (type) => {
    if (!canSubmit() && type !=='close') {
      return;
    }

    let selectedStartInfo = (sortSettings && sortSettings[starScore - 1]) || null;
    let selectedLabel = [];

    if (selectedStartInfo && Array.isArray(selectedStartInfo.label)) {
      selectedLabel = selectedStartInfo.label.filter((item, index) => {
        return selectedLabelIndex.findIndex(i => i === index) >= 0;
      })
    }

    if (type !== 'close' && selectedStartInfo && selectedStartInfo.label_select === 1 && selectedLabel.length <= 0) {
      tostTip('请选择标签');
      return;
    }
    if (type !== 'close' && selectedStartInfo && selectedStartInfo.evaluate === 1 && text.trim() === "") {
      tostTip('请填写评价内容');
      return;
    }

    let params = {
      star: starScore,
      evaluate: text.trim() || '',
      star_content: (selectedStartInfo && selectedStartInfo.explain),
      label_slice: selectedLabel,
    };

    if(config?.is_solved && config?.is_solved === 1){
      params.is_solved = likeState === '1' ? 1 : 2;
    }


      if(!feedbackInitiative){
        params.evaluate_type = 2;
        radarLog({ c: 'ypcg_evaluation' });
      }else{
        params.evaluate_type = 1;
        radarLog({ c: 'zd_evaluation' });
      }

    if(type && type === 'close'){
      params.is_solved = 0;
      params.star = 0;
    }

    submitFeedback(params,type);
  }

  const renderMobile = () => {
    return <div className={styles['mobile-container']}>
      <div
        className={`${styles['shade-container']} ${showFeedback ? styles['-active'] : ''} `}>

      </div>

      <div
        className={`${styles['feedback-container']} ${showFeedback ? styles['-active'] : ''} `}>

        <div className={styles['feedback-header-container']}>
          <span>服务评价</span>
          <svg onClick={closeFeedback} className={`icon ${styles['close-btn']}`} aria-hidden="true">
            <use xlinkHref={`#icon-zhike-feedback-close`}/>
          </svg>
        </div>


        {config?.is_solved && config?.is_solved === 1 ? <div className={styles['feedback-content']}>
          <div className={styles['like-or-unlike-container']}>
            <p>是否解决了您的问题?</p>
            <div className={`${styles['like-btn-group']} ${likeState != '' ? styles['-disable'] : ''}`}>
              <div className={`${styles['like-btn']} ${likeState === '1' ? styles['-active'] : ''}`}
                   onClick={() => feedbackClick('1')}>
                <svg className={`icon ${styles['icon']}`} style={{display: (likeState != '1' ? '' : 'none')}}
                     aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-like-gray`}/>
                </svg>
                <img style={{display: (likeState === '1' ? '' : 'none')}}
                     src="https://p4.ssl.qhimg.com/t01bce19cc99e72d6a3.png"/>
                <span>已解决</span>
              </div>
              <div className={`${styles['like-btn']} ${likeState === '2' ? styles['-active'] : ''}`}
                   onClick={() => feedbackClick('2')}>
                <svg className={`icon ${styles['icon']}`} style={{display: (likeState != '2' ? '' : 'none')}}
                     aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-unlike-gray`}/>
                </svg>
                <img style={{display: (likeState === '2' ? '' : 'none')}}
                     src="https://p0.ssl.qhimg.com/t01da1ac8d33a0eadc5.png"/>
                <span>未解决</span>
              </div>
            </div>
          </div>
        </div> :null}
        <div className={styles['score-container']}>

          {
            sortSettings && sortSettings.map((item, index) => {
              return <div className={styles['star-icon']} key={index} onClick={() => {
                if (item.level != starScore) {
                  setStarScore(item.level);
                  setSelectedLabelIndex([]);
                }
              }}>
                <svg style={{display: (starScore >= item.level ? 'none' : '')}} className={`icon ${styles['icon']}`}
                     aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-unlike-star`}/>
                </svg>
                <img style={{display: (starScore >= item.level ? '' : 'none')}}
                     src="https://p5.ssl.qhimg.com/t0171e17a40594c4e69.png"/>
              </div>
            })
          }

          <p className={styles['score-desc']}>
            {
              starScore > 0 ? (sortSettings && sortSettings[starScore - 1] && sortSettings[starScore - 1].explain) : ''
            }
          </p>

        </div>


        {
          (sortSettings && sortSettings[starScore - 1] && Array.isArray(sortSettings[starScore - 1].label))
          && sortSettings[starScore - 1].label.length > 0 ?
            <div className={styles['mark-container']}>

              {
                sortSettings[starScore - 1].label.map((item, index) => {
                  let tempIndex = selectedLabelIndex.findIndex(i => i === index);
                  return <div key={index} className={`${styles['item']} ${tempIndex >= 0 ? styles['-active'] : ''}`}
                              onClick={() => {
                                if (tempIndex >= 0) {
                                  let tempArr = [...selectedLabelIndex];
                                  tempArr.splice(tempIndex, 1);
                                  setSelectedLabelIndex(tempArr);
                                } else {
                                  setSelectedLabelIndex([...selectedLabelIndex, index]);
                                }
                              }}>
                    {item}
                  </div>
                })
              }
            </div>
            : null
        }


        <div className={styles['feedback-text-container']}>
          <TextArea
            maxLength={500}
            showCount
            style={{height:'100%'}}
            placeholder={(config && config.evaluate_text) || '欢迎给我们服务提建议~'}
            onChange={handleChange}
          />
        </div>


        <div className={`${styles['submit-btn']} ${canSubmit() ? '' : styles['-disable']}`}
             onClick={submitHandle}
        >
          <span>提交</span>
        </div>


      </div>
    </div>
  }

  const render = () => {
    return <div className={styles['pc-container']}>
      <div
        className={`${styles['shade-container']} ${showFeedback ? styles['-active'] : ''} `}>
      </div>


      <div
        className={`${styles['feedback-container']} ${showFeedback ? styles['-active'] : ''} `}>

        <div className={styles['feedback-header-container']}>
          <span>服务评价</span>
          <svg onClick={closeFeedback} className={`icon ${styles['close-btn']}`} aria-hidden="true">
            <use xlinkHref={`#icon-zhike-feedback-close`}/>
          </svg>
        </div>

        {config?.is_solved && config?.is_solved === 1? <div className={styles['feedback-content']}>
          <div className={styles['like-or-unlike-container']}>
            <p>是否解决了您的问题?</p>
            <div className={`${styles['like-btn-group']} `}>


              <div className={`${styles['like-btn']} ${likeState === '1' ? styles['-active'] : ''}`}
                   onClick={() => feedbackClick('1')}
              >

                <svg className={`icon ${styles['radio']}`} aria-hidden="true">
                  <use xlinkHref={`${likeState === '1' ? '#icon-zhike-radio1' : '#icon-zhike-radio'}`}/>
                </svg>
                <span>已解决</span>
              </div>


              <div className={`${styles['like-btn']} ${likeState === '2' ? styles['-active'] : ''}`}
                   onClick={() => feedbackClick('2')}
              >

                <svg className={`icon ${styles['radio']}`} aria-hidden="true">
                  <use xlinkHref={`${likeState === '2' ? '#icon-zhike-radio1' : '#icon-zhike-radio'}`}/>
                </svg>
                <span>未解决</span>
              </div>

            </div>
          </div>
        </div>:null}



        <div className={styles['score-container']}>

          {
            (config && config.invite_text) ? <p className={styles['desc']}>
              {config.invite_text}
            </p> : null
          }

          {
            sortSettings && sortSettings.map((item, index) => {
              return <div className={styles['star-icon']} key={index} onClick={() => {
                if (item.level != starScore) {
                  setStarScore(item.level);
                  setSelectedLabelIndex([]);
                }
              }}>
                <svg className={`icon ${styles['icon']}`}
                     aria-hidden="true">
                  <use
                    xlinkHref={`${(starScore >= item.level ? '#icon-zhike-pc-feedback-star' : '#icon-zhike-pc-feedback-unlike-star')}`}/>
                </svg>

              </div>
            })
          }

          <p className={styles['score-desc']}>
            {
              starScore > 0 ? (sortSettings && sortSettings[starScore - 1] && sortSettings[starScore - 1].explain) : ''
            }
          </p>


        </div>

        {
          (sortSettings && sortSettings[starScore - 1] && Array.isArray(sortSettings[starScore - 1].label))
          && sortSettings[starScore - 1].label.length > 0 ?
            <div className={styles['mark-container']}>

              {
                sortSettings[starScore - 1].label.map((item, index) => {
                  let tempIndex = selectedLabelIndex.findIndex(i => i === index);
                  return <div key={index} className={`${styles['item']} ${tempIndex >= 0 ? styles['-active'] : ''}`}
                              onClick={() => {
                                if (tempIndex >= 0) {
                                  let tempArr = [...selectedLabelIndex];
                                  tempArr.splice(tempIndex, 1);
                                  setSelectedLabelIndex(tempArr);
                                } else {
                                  setSelectedLabelIndex([...selectedLabelIndex, index]);
                                }
                              }}>
                    {item}
                  </div>
                })
              }
            </div>
            : null
        }

        <div className={styles['feedback-text-container']}>
          <TextArea
            maxLength={500}
            showCount
            style={{height:'100%'}}
            placeholder={(config && config.evaluate_text) || '欢迎给我们服务提建议~'}
            onChange={handleChange}
          />
        </div>

        <div className={styles['btn-container']}>
          <div className={`${styles['submit-btn']} ${canSubmit() ? '' : styles['-disable']}`}
               onClick={submitHandle}
          >
            <span>提交</span>
          </div>
        </div>


      </div>

    </div>
  }


  return (
    isMobile ? renderMobile() : render()
  )
}