import React, {useState, useEffect, useRef} from 'react';
import PCCard from './PCCard';
import H5Card from './H5Card';

export default function ShuntCard(props) {
  const {
    isMobile = false,
  } = props;

  const renderMobile = () => {
    return <div>
      <H5Card {...props} />
    </div>
  };
  const render = () => {
    return <div>
      <PCCard {...props} />
    </div>
  };
  return (
    isMobile ? renderMobile() : render()
  )
}