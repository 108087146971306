import React, {Fragment, useState, useEffect, useRef} from 'react'
import styles from './index.less'
import {replyAnswer, matchGame} from '../static/api'
import {toast} from '../static/util/utils'

/**
 * @param {String} props.type 区分左右对话框
 * @param {Boolean} props.isMobile 是否移动端
 * @param {Boolean} props.feedback 显示点赞
 * @param {String } props.content 内容
 * @param {Array} props.list 可选内容
 * @param {Object} props.info 角色信息
 * @param {String} props.id feedback回传id
 * @param {Function} props.triggerSend 问题list的点击回调
 * @param {Function} props.triggerGameSend 游戏名字list的点击回调
 * @param {Boolean} props.innerHtml 富文本展示
 */

var tostTip = toast();

export default function Dialog(props) {
  const {
    type = "left",
    showFeedback = false,
    isMobile = false,
    content = '',
    showMore = false,
    list = [],
    gameList = [],
    info = {},
    id = '',
    triggerSend = () => {
    },
    triggerGameSend = () => {
    },
    innerHtml = false,
    component: Component = null,
    options,
    typedInput,
    scrollToBottom = ()=>{

    },
    closeTyped = ()=>{
    },
    msgId,
  } = props
  const [arr, setArr] = useState([]);
  //展示select框
  const [showSelect, setshowSelect] = useState(false);
  const [showGame, setshowGame] = useState(false);
  const [gamePlatform, setgamePlatform] = useState('');
  const [gameName, setgameName] = useState('');
  const [collapse, setCollapse] = useState(false);


  //移动端点赞状态控制
  const [likeState, setLikeState] = useState('');


  const textRef = useRef();

  useEffect(() => {
    // console.log('dialogRef',dialogRef.current && dialogRef.current.clientHeight);
    if (textRef.current && textRef.current.clientHeight && textRef.current.clientHeight > 110) {
      !showMore && list.length === 0 && setCollapse(true);
    }
  }, []);

  // console.log('初始化');

  var obj = {mobile: '手游', wan: '页游', youxi: '端游'}

  function changeName(e) {
    if (!gamePlatform) {
      tostTip('请先选择游戏平台')
      return;
    }
    setgameName(e.target.value)
    matchGame({keyword: e.target.value, pkey: gamePlatform}).then((res) => {
      var gameList = res.data || []
      setshowGame(gameList.length)
      setArr(gameList)
    }).catch((err) => {
      tostTip(err.msg)
    });

  }

  // 选择游戏
  function GameSend(item) {
    //去掉游戏展示框
    setshowGame(false)
    setgameName(item)
    triggerGameSend(item)
  }

  // 展开游戏平台
  function selectedItem() {
    setshowSelect(!showSelect)
  }

  // document.addEventListener('click',()=>{
  //   console.log(23);

  //   if(showSelect) setshowSelect(false)
  //   if(showGame) setshowGame(false)
  // },true)
  // 选择游戏平台
  function selectedGame(item, event) {
    //把游戏名称清空
    setgameName('')
    setshowSelect(false)
    setgamePlatform(item)
  }

  //失去焦点
  function initshowGame() {
    setTimeout(() => {
      setshowSelect(false);
      setshowGame(false)
    }, 300);
  }

  const [clickable, setClickable] = useState(showFeedback) // 反馈可点击
  // 点赞请求
  function feedbackClick(e, reply) {

    if (!clickable) {
      return
    }
    setClickable(false)
    setLikeState(reply + '');

    replyAnswer({id, reply})
    e && $(e.target).siblings().hide(300)
  }


  const renderWeakText = () => {
    return (content ? <div className={styles['kf_weak-text-container']}>
      {content}
    </div> : null);
  }

  useEffect(() => {
    if (textRef.current) {
      if (typedInput) {
        scrollToBottom(0);
      }
    }
    return ()=>{
      closeTyped(msgId);
    }
  }, [textRef,typedInput,content]);
  const render = () => {
    if (type === 'weakText') {
      return renderWeakText();
    }
    const className = isMobile ? 'mkf_dialog' : 'kf_dialog'
    return (
      <div
        style={{position:'relative'}}
        className={`${type === 'left' ? styles[className] : `${styles[className]} ${styles.u_dialog}`} ${Component ? styles['-no-style'] : ''} ${collapse ? styles['collapse_dialog'] : ''}`}>
        {type === 'left' ? (
          <Fragment>
            <img className="kf_dialog-pic"
                 src={info.kfInfo && info.kfInfo.url || "https://p3.ssl.qhimg.com/t010a6232b25737b10d.png"}/>
          </Fragment>
        ) : (
          <Fragment>
            <img className="kf_dialog-pic"
                 src={info.uInfo && info.uInfo.url || "https://p1.ssl.qhimg.com/t019c6ef454bf1f20ad.png"}/>
          </Fragment>
        )}
        {/* 内容 */}
        <div style={{width: '100%'}}>
          <div
            className={`kf_dialog-content ${(type === 'left' && showFeedback && id) ? styles['feed-back-content'] : ''}`}>
            {!showMore && list.length == 0 ? (
              <Fragment>
                {content ? <>
                  {innerHtml ?
                    (<div ref={textRef} className="kf_dialog-text" dangerouslySetInnerHTML={{__html: content}}></div>) :
                    (<div ref={textRef} className="kf_dialog-text">{content}</div>)}
                </> : null}
                {Component ? <Component {...props} options={options}/> : null}

                {
                  collapse ? <div className={styles['collapse-btn']} onClick={() => {
                    setCollapse(false)
                  }}>
                    <span>展开</span>
                    <svg className={`icon `} aria-hidden="true">
                      <use xlinkHref={`#icon-zhike-arrow-down-blue`}/>
                    </svg>
                  </div> : null
                }
              </Fragment>
            ) : (
              <div className="kf_dialog-text kf_dialog-list">
                {
                  content ? <div className="kf_dialog-list-title">
                    <p dangerouslySetInnerHTML={{__html: content}}></p>
                  </div> : null
                }

                {list.map((item, k) => <div className="kf_dialog-list-content" key={k}
                                            onClick={() => triggerSend(typeof item === 'string' ? item : item.question, item.question_id)}>
                  {(k + 1) + '、' + (typeof item === 'string' ? item : item.question)}
                </div>)}

              </div>
            )}
            {/* 点赞 */}
            {type === 'left' && showFeedback && id && (
              <div className={`${styles['dialog_feedback']} ${likeState != '' ? styles['-disable'] : ''}`}>
                <div className={`${styles['feedback_btn']} ${likeState === '1' ? styles['-active'] : ''}`}
                     onClick={() => feedbackClick(null, 1)}>
                  <svg className={`icon ${styles['icon']}`} aria-hidden="true">
                    <use xlinkHref={`#icon-zhike-like${likeState === '1' ? '-active-blue' : ''}`}/>
                  </svg>
                  <span>有&nbsp;&nbsp;用</span>
                </div>
                <div className={`${styles['feedback_btn']} ${likeState === '0' ? styles['-active'] : ''}`}
                     onClick={() => feedbackClick(null, 0)}>
                  <svg className={`icon ${styles['icon']}`} aria-hidden="true">
                    <use xlinkHref={`#icon-zhike-unlike${likeState === '0' ? '-active-blue' : ''}`}/>
                  </svg>
                  <span>没&nbsp;&nbsp;用</span>
                </div>
              </div>
            )}
          </div>
        </div>

      </div>
    )
  }

  return render();

}
