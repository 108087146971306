import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './index.less';
import useMutationObserver from "../../../static/util/useMutationObserver";

const batchSize = 5;
const Pagination = (props) => {
  const {children, containerRef, distance = 60, type} = props;
  const childRef = useRef(null);
  const Children = React.cloneElement(children, {ref: childRef});
  // const Container = React.cloneElement(container, {ref: containerRef});
  const [showPagination, setShowPagination] = useState(false);
  const [leftDisable, setLeftDisable] = useState(false);
  const [rightDisable, setRightDisable] = useState(false);
  const scrollLeft = useRef(0);

  useEffect(() => {
    // console.log(childRef?.current.clientWidth, containerRef?.current.clientWidth);
    // console.log(childRef?.current.clientWidth, childRef?.current.scrollWidth);
    if (childRef?.current.scrollWidth > containerRef?.current.clientWidth) {
      setShowPagination(true);
    }
    judgeDisable();
  }, []);

  useMutationObserver(childRef, () => {
    if (childRef?.current.scrollWidth > containerRef?.current.clientWidth) {
      setShowPagination(true);
    } else {
      setShowPagination(false);
    }
  })

  useEffect(() => {
    scrollLeft.current = 0;
    judgeDisable();
  }, [showPagination]);

  const judgeDisable = () => {
    setLeftDisable(scrollLeft?.current <= 0 ? true : false);
    setRightDisable(childRef?.current?.scrollWidth <= (scrollLeft?.current + childRef?.current.clientWidth) ? true : false);
    // console.log(childRef?.current?.scrollWidth,scrollLeft?.current, childRef?.current.clientWidth)
  }

  const clickHandle = (direction) => {
    let dir = direction === 'left' ? -1 : 1;
    let s = childRef.current.scrollLeft + distance * dir;
    childRef.current.scrollLeft = s;
    scrollLeft.current = s;
    judgeDisable();
  }
  return <>
    {Children}
    {showPagination && <div className={styles['pagination']}>
      {type === 'business' && <div className={styles['items_img']}></div>}
      <div className={`${styles['item']} ${type === 'business' ? styles['business-item'] : ''}`} onClick={() => clickHandle('left')}>
        <i className={`icon-zhike icon-zhike-left ${styles['icon']} ${leftDisable ? styles['-disable'] : ''}`}></i>
      </div>
      <div className={`${styles['item']} ${type === 'business' ? styles['business-item'] : ''}`} onClick={() => clickHandle('right')}>
        <i className={`icon-zhike icon-zhike-right ${styles['icon']}  ${rightDisable ? styles['-disable'] : ''}`}></i>
      </div>
    </div>}
  </>

}

const PCCard = (props) => {
  const {
    componentExtra = {},
    triggerSend = () => {

    },
    msgId
  } = props;


  console.log('componentExtra', props);

  const {common_message} = componentExtra;

  const setCommonsAndGroups = (common) => {
    setActiveCommon(common);
    setSizeIndex(0);
    if (Array.isArray(common?.common_groups) && common?.common_groups.length > 0) {
      setActiveGroup(common?.common_groups[0]);
    } else {
      setActiveGroup(null);
    }
  }


  useEffect(() => {
    if (Array.isArray(componentExtra?.commons) && componentExtra?.commons.length > 0) {
      setCommons(componentExtra?.commons);
      setCommonsAndGroups(componentExtra?.commons[0])
    }
  }, [componentExtra]);

  const [commons, setCommons] = useState([]);
  const [activeCommon, setActiveCommon] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const containerRef = useRef(null);
  const productContainerRef = useRef(null);
  const [sizeIndex, setSizeIndex] = useState(0);
  return <div className={styles['container']}>
    {commons && commons.length > 1 && <div className={styles['product_container']}>
      <div ref={productContainerRef} className={styles['items-container']}>
        <Pagination containerRef={productContainerRef} distance={90} type="business">
          <div className={styles['items']}>
            {
              commons && commons.map(common => {
                return <div className={` ${common?.record_id === activeCommon?.record_id ? styles['wrap'] : ''}`}
                            key={common.record_id}>
                  <div onClick={() => {
                    setCommonsAndGroups(common);
                    setSizeIndex(0);
                  }} key={common.record_id}
                       className={`${styles['product_item']} ${common?.record_id === activeCommon?.record_id ? styles['-active'] : ''}`}>
                    {common?.icon &&
                      <div style={{backgroundImage: `url(${common?.icon})`}} className={styles['icon']}></div>
                    }
                    <div className={styles['desc']}>{common.name}</div>
                  </div>
                </div>
              })
            }

          </div>
        </Pagination>
      </div>

    </div>}

    <div className={styles['card-container']}>
      {componentExtra?.commons_img &&
        <div style={{backgroundImage: `url(${componentExtra?.commons_img})`}} className={styles['side-view']}></div>
      }
      <div className={styles['content']}>
        <div className={styles['content-font']}>
        {activeCommon && activeCommon?.common_groups && (activeCommon?.common_groups.length > 1 || activeCommon?.common_groups[0]?.name) ?
          <div className={styles['header-container']}>
            <div ref={containerRef} className={styles['tabs-container']}>
              <Pagination containerRef={containerRef} type="grouping">
                <div className={styles['tabs']}>
                  {
                    activeCommon && activeCommon?.common_groups && activeCommon?.common_groups.map(group => {
                      return <div key={group.record_id}
                                  onClick={() => {
                                    setActiveGroup(group)
                                  }}
                                  className={`${styles['tab']} ${group?.record_id === activeGroup?.record_id ? styles['-active'] : ''}`}>
                        {group?.name}
                      </div>
                    })
                  }
                </div>
              </Pagination>
            </div>
          </div> : <div className={styles['guide-container']}>
            {/* <img src={'https://p0.qhimg.com/t11098f6bcdb373720a77284301.png'}/> */}
            <span>{common_message}</span>
          </div>}
        <div className={styles['list-container']}>
          {
            activeGroup && activeGroup.commons &&
            Array.from({length: activeGroup.commons.length > batchSize ? batchSize : activeGroup.commons.length}, (_, i) => activeGroup.commons[(sizeIndex + i) % activeGroup.commons.length]).map(common => {
              return <div key={common.question_id} className={styles['list-item']} onClick={() => {
                // triggerSend(common.name || common?.question, common.record_id)
                triggerSend(common.name || common?.question, common.question_id || common.record_id|| common.faq_data_id, {
                  msgId,
                  record: common
                })
              }}>
                <div className={styles['desc']}>{common.name || common?.question}</div>
                <svg className={`icon ${styles['icon']}`} aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-bianzu2`}/>
                </svg>
              </div>
            })
          }
        </div>
        </div>
        {activeGroup && activeGroup.commons && activeGroup && activeGroup.commons.length > 5 &&
          <div onClick={() => {
            setSizeIndex(sizeIndex + batchSize)
          }} className={styles['change-btn']}>
            <span>换一换</span>
            <i style={{fontSize: '12px'}} className="icon-zhike icon-zhike-Refresh1"></i>
          </div>}
      </div>
    </div>

  </div>
}

export default PCCard;
