// const CryptoJS = require("crypto-js");
const chattingRecordsKey = '_zk_u_c_r_';
const pako = require('pako');

// const LZ = require('lz-string');
// const compressed = pako.deflate(obj, { to: 'string' });
// const lzPress = LZ.compressToUTF16(obj);
//
// const restored = pako.inflate(compressed, { to: 'string' });
// const ciphertext = CryptoJS.AES.encrypt(obj, `${chattingRecordsKey}encrypt`).toString();
// console.log(compressed.length);
// console.log(restored.length);
// console.log(lzPress.length);
// console.log(ciphertext.length);
// console.log(CryptoJS.AES.encrypt(compressed, `${chattingRecordsKey}encrypt`).toString().length)

// Encrypt
// const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), chattingRecordsKey).toString();
// const compressed = pako.deflate(ciphertext);
// const compressed = zlib.deflateSync(ciphertext);
// const bytes  = CryptoJS.AES.decrypt(ciphertext, chattingRecordsKey);
// const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// console.log(JSON.stringify(data).length,ciphertext.length,compressed.toString().length);
// console.log(JSON.stringify(data).toString());
// console.log(decryptedData);

//存储聊天记录
function setChattingRecords(data) {
  try {
    const compressed = pako.deflate(JSON.stringify(data), { to: 'string' });
    // const ciphertext = CryptoJS.AES.encrypt(compressed, `${chattingRecordsKey}encrypt`).toString();
    localStorage.setItem(chattingRecordsKey, compressed);
    return true;
  } catch (e) {}
  return false;
}

function getAllChattingRecords() {
  try {
    const ciphertext = localStorage.getItem(chattingRecordsKey);
    // const bytes  = CryptoJS.AES.decrypt(ciphertext, `${chattingRecordsKey}encrypt`);
    // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    const restored = JSON.parse(pako.inflate(ciphertext, { to: 'string' }));
    return restored;
  } catch (e) {}
  return null;
}

//聊天记录存储
export function saveChattingRecords(sid, data) {
  try {
    let datas = getAllChattingRecords() || {};
    datas[sid] = data;
    datas[sid].expires = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
    return setChattingRecords(datas);
  } catch (e) {}
  return false;
}

//获取聊天记录
export function getChattingRecords(sid) {
  try {
    if (sid) {
      let allRecords = getAllChattingRecords();
      if (allRecords && allRecords[sid]) {
        console.log('allChattingRecords', allRecords);
        return allRecords[sid];
      }
      // console.log(allRecords);
    }
  } catch (e) {}
  return null;
}

//清理过期聊天记录
export function lazyDeleteChattingRecords(ids) {
  try {
    let datas = getAllChattingRecords() || {};
    Object.keys(datas).map(key => {
      if (
        (Array.isArray(ids) && ids.indexOf(key) < 0) ||
        datas[key].expires < new Date().getTime()
      ) {
        delete datas[key];
      }
    });
    return setChattingRecords(datas);
  } catch (e) {}
  return false;
}
