import xssFilters from "xss";
import xssOptions from "../static/xssFiltersOptions";
import {getAnswer} from "../static/api";
import {operateMultiId, uuid} from "../static/util/utils";

export function convertOrderList(data={}){
  const {select_type,list} = data || {};
  let result = [];
  if(select_type === 'order' && Array.isArray(list)){
    result = list.map(item=>{
      return {
        id:item.order_id,
        question_id:item.order_id,
        question:`会员名称：${item.member_desc}<br/>商品名称：${item.custom_desc}<br/>产品名称：${item.product} <br/>订单号：${item.order_id}<br/>订单时间：${item.order_pay_time}<br/>`
      }
    })
  }else if(Array.isArray(list)){
    result = list.map(item=>{
      return {
        id:item.id,
        question_id:item.id,
        question:item.content || item.question,
      }
    })
  }
  return result;
}

async function processControl({e, id, reducer, info, t, loginControl,resp}) {
  // if (!loginControl) {
  //   operateMultiId({
  //     clear: true
  //   })
  //   return;
  // }
  if(resp){
    console.log('resp',resp);
    reducer({
      type: 'dealResponseWithList', param: {
        content: resp.data.answer || noAnswer,
        list: convertOrderList(resp.data?.select_list),
        id: resp.data.id || '',
        commons: info.kfInfo.guess,
        msgId: uuid(),
      }
    });
    return;
  }
  // let res = await loginControl.login();
  // if(!res){
  //   return;
  // }
  // console.log(arguments);
  const stringToSend = xssFilters(e.trim(), xssOptions);
  if (!stringToSend) {
    return
  }
  let param = {game: '', question: ''} // 请求答案的参数
  param.question = stringToSend
  param.select_id = id;
  param.refund = '1';
  // 显示输入
  reducer({type: 'waitQuestion', param: stringToSend})
  //如果存在域账号则带入(360+适配)
  if (info && info.uInfo && info.uInfo.account) {
    param.account = info.uInfo.account;
  }
  let method = getAnswer;
  // 获取问题的答案
  method(param).then(resp => {
    console.log(resp);
    try {
      if (parseInt(resp.errno) !== 0) {
        reducer({
          type: 'init', param: {
            content: resp.errmsg || t('wrong')
          }
        })
        null[0];
      }

      reducer({
        type: 'dealResponseWithList', param: {
          content: resp.data.answer || "",
          list: convertOrderList(resp.data?.select_list),
          id: resp.data.id || '',
          commons: info.kfInfo.guess,
          msgId: uuid(),
        }
      });

      //存在接待组id 转人工
      if(resp.data?.reception_id){
        connectIM(false, null,[resp.data?.reception_id],1);
        null[0];
      }

      if (!resp.data.dialogue_id) {
        null[0];
      }
      if (resp?.data?.dialogue_id) {
        //如果有多轮对话id则保存
        operateMultiId({
          id: resp.data.dialogue_id, expand: {is_big_vip: resp.data?.is_big_vip}
        });
        return;
      }


    } catch (e) {
      operateMultiId({
        clear: true
      })
    }
  }).catch(e => {
    reducer({
      type: 'init', param: {
        content: t('wrong')
      }
    })
    //退出多轮对话
    operateMultiId({
      clear: true
    })
  });

}

export default processControl;