import React from "react";
import styles from './index.less'

export default function SideBar(props) {
  const {isMobile = false,} = props

  if (isMobile) {
    return null;
  }
  // console.log(info.color)
  return (
    <div className={styles['side-bar']}>
      <div className={styles['header']}>
        <span className={styles['title']}>推荐</span>
      </div>

      <div className={styles['content']}>
        <div className={styles['container']}>
          <a href="https://dev.tg.wan.360.cn/?channel=515330286&placeid=&gkey=xxycs" target="_blank">
            <img
              src="https://p0.ssl.qhimg.com/t01dd0236b3f7ff8a23.jpg" alt="" className={styles['item']}/>
          </a>

          <a href="https://fuli.360.cn/index.html?appid=1085&tj_all_from=zhike#1" target="_blank">
            <img
              src="https://p2.ssl.qhimg.com/t01a9a42fecce560b40.jpg" alt="" className={styles['item']}/>
          </a>
          {/*<a href="https://fuli.360.cn/index.html?appid=1085&tj_all_from=zhike#3" target="_blank">*/}
          {/*  <img src="https://p4.ssl.qhimg.com/t013bf5498e5d8881ca.png" alt="" className={styles['item']}/>*/}
          {/*</a>*/}
          {/*<a href="https://fuli.360.cn/index.html?appid=1085&tj_all_from=zhike" target="_blank">*/}
          {/*  <img src="https://p4.ssl.qhimg.com/t010978a77ff4ba8872.png" alt="" className={styles['item']}/>*/}
          {/*</a>*/}
          {/*<a href="https://fuli.360.cn/index.html?appid=1085&tj_all_from=zhike#c11" target="_blank">*/}
          {/*  <img src="https://p5.ssl.qhimg.com/t010c59397a8257bab3.png" alt="" className={styles['item']}/>*/}
          {/*</a>*/}

        </div>
      </div>
    </div>
  )
}