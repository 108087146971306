import Cookies from 'js-cookie';

const queryString = require('query-string');
const {rid} = queryString.parse(location.search);

const randomTag = Math.floor(Math.random() * 100000)

export function uuid(len = 32, radix) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|ccwork|OpenHarmony|HarmonyOS/i.test(navigator.userAgent)
}

export function isTuiTui() {
  return /ccwork/i.test(navigator.userAgent)
}

export function toast(msg) {
  var box = '';
  return function (msg) {
    if (!box) {
      box = document.createElement('div')
      box.className = 'toast_box'
      var content = document.createElement('div')
      content.className = 'toast'
      content.innerHTML = msg
      box.appendChild(content);
      document.body.append(box)
      setTimeout(() => {
        document.body.removeChild(box);
        box = ''
      }, 2000);
    }
  }
}

export function throttle(fn, wait) {
  var resultTime = 0;
  return function (e) {
    console.log(e);
    console.log(resultTime);

    var now = +new Date(); //获取当前时间
    var _this = this;
    if (now - resultTime > wait) {
      fn.apply(_this, arguments);
      resultTime = now;
      console.log(resultTime);

    }
  };
}

let debounceTimeout;

export function debounce(func, wait) {
  return function (e) {
    clearTimeout(debounceTimeout);
    let context = this, args = arguments

    debounceTimeout = setTimeout(function () {
      func.apply(context, args);
    }, wait);
  };
}

export function fixKeyboard() {

  const ua = window.navigator.userAgent.toLocaleLowerCase();
  const isIOS = /iphone|ipad|ipod/.test(ua);
  const isAndroid = /android/.test(ua);
  if (isIOS) {
    let isReset = true; //是否归位
    const focusinHandler = () => {
      isReset = false; //聚焦时键盘弹出，焦点在输入框之间切换时，会先触发上一个输入框的失焦事件，再触发下一个输入框的聚焦事件
    };
    const focusoutHandler = () => {
      isReset = true;
      setTimeout(() => {
        //当焦点在弹出层的输入框之间切换时先不归位
        if (isReset) {
          window.scroll(0, 0); //确定延时后没有聚焦下一元素，是由收起键盘引起的失焦，则强制让页面归位
        }
      }, 30);
    };
    document.body.addEventListener('focusin', focusinHandler);
    document.body.addEventListener('focusout', focusoutHandler);
  }

  if (isAndroid) {
    const originHeight = document.documentElement.clientHeight || document.body.clientHeight;
    const resizeHandler = () => {
      const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const activeElement = document.activeElement;
      if (resizeHeight < originHeight) {
        // 键盘弹起后逻辑
        if (activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA")) {
          setTimeout(() => {
            activeElement.scrollIntoView(false);//焦点元素滚到可视区域的问题
          }, 0)
        }
      } else {
        // 键盘收起后逻辑
      }
    };
    window.addEventListener('resize', resizeHandler);
  }
}

export function isWeixin() {
  return !!window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
}

export function generateUTag() {
  // let tag = Cookies.get('uTag');
  // if (!tag) {
  //   Cookies.set('uTag', uuid(32), {expires: 365, domain: '360.cn'});
  // }

  let localTag;
  try{
    localTag = uuid(32);
    localTag = window?.localStorage?.getItem('uTag') || Cookies.get('_ZK_U_TAG') || uuid(32);
    Cookies.set('_ZK_U_TAG',localTag);
    if (window.localStorage) {
      window.localStorage.setItem('uTag', localTag);
      // localTag = window.localStorage.getItem('uTag');
      // if (!localTag) {
      //   window.localStorage.setItem('uTag', uuid(32));
      // }
    }

  }catch (e){

  }

  return localTag;
}


let uTag;

export function getUTag() {
  if(!uTag){
    uTag = generateUTag();
  }
  return uTag;
  // if (window.localStorage) {
  //   let localTag = window.localStorage.getItem('uTag');
  //   if (!localTag) {
  //     localTag = uuid(32);
  //     window.localStorage.setItem('uTag', localTag);
  //   }
  //   return localTag;
  // } else {
  //   if (!uTag) {
  //     uTag = uuid(32);
  //   }
  //   return uTag;
  // }
  // return null;
}


export function generateWid(refresh) {
  let u_id = window?.u_id || "";
  const setId = id => {
    try {
      window.localStorage.setItem(`wid-${rid}-${u_id}`, JSON.stringify({id: id, expires: new Date().getTime() + 900000}));
    }catch (e){

    }

  }
  let localTag;
  try {
    if (window.localStorage) {
      localTag = window.localStorage.getItem(`wid-${rid}-${u_id}`);
      let id = uuid(32);
      if ((!localTag) || refresh) {
        setId(id);
      } else {
        try {
          let item = JSON.parse(localTag);
          if (new Date().getTime() > item.expires) {
            setId(id);
          } else {
            id = item.id;
            setId(id);
          }
        } catch (e) {
          setId(id);
        }
      }
      return id;
    }
  }catch (e){

  }

  return null;
}

export function operateMultiId({refresh = false, clear = false, id, expand} = {}) {
  let u_id = window?.u_id;
  if (!u_id) {
    return {id: ''};
  }
  let key = `multi-${rid}-${u_id}`;
  const setId = (id, expand) => {
    try {
      window.localStorage.setItem(key, JSON.stringify({id: id, expand, expires: new Date().getTime() + 900000}));
    }catch (e){

    }
  }
  let localTag;
  try{
    if (window.localStorage) {
      localTag = window.localStorage.getItem(key);
      // id = id;
      if ((!id && !refresh) || clear) {
        window.localStorage.removeItem(key);
        return {id: ''};
      }
      if (refresh && localTag) {
        try {
          let item = JSON.parse(localTag);
          if (new Date().getTime() > item.expires) {
            null[0];
          } else {
            id = item.id;
            setId(id, item.expand);
            return {id, expand: item.expand};
          }
        } catch (e) {
          window.localStorage.removeItem(key);
          return {id: ''};
          // setId(id);
        }
      }
      if (id) {
        setId(id, expand);
        return {id, expand};
      }

    }
  }catch (e){

  }

  return {id: ''};
}

export function getGuideIndex(model) {
  let wid = generateWid() || "";
  let u_id = window?.u_id || "";
  let key = `guide-${model === 1 ? wid : `${rid}-${u_id}`}`
  let index = 0;
  let localTag;
  try {
    if (window.localStorage) {
      localTag = window.localStorage.getItem(key);
      if (localTag) {
        try {
          let item = JSON.parse(localTag);
          if (!(new Date().getTime() > item.expires)) {
            index = item.index;
            if (item.did) {
              return null;
            }
          }
        } catch (e) {
        }
      }
    }
  }catch (e){

  }

  return index;
}

export function updateGuideIndex(index, model) {
  try {
    let wid = generateWid() || "";
    let u_id = window?.u_id || "";
    let key = `guide-${model === 1 ? wid : `${rid}-${u_id}`}`
    if (window.localStorage && index !== undefined) {
      if (index === null) {
        // window.localStorage.removeItem(`guide-${wid}`);
        window.localStorage.setItem(key, JSON.stringify({
          index: index,
          expires: model === 1 ? (new Date().getTime() + 1800000) :
            (new Date(new Date().toLocaleDateString()).getTime() + 86400000),
          did: true,
        }));
      } else {
        window.localStorage.setItem(key, JSON.stringify({
          index: index,
          expires: model === 1 ? (new Date().getTime() + 1800000) :
            (new Date(new Date().toLocaleDateString()).getTime() + 86400000),
          did: false,
        }));
      }
    }
  }catch (e){

  }

}

export function clearOutOfDateWid() {
  try {
    if (window.localStorage) {
      let storage = window.localStorage;
      for (let key in storage) {
        if (key && (key.indexOf('wid-') >= 0 || key.indexOf('guide-'))) {
          try {
            let item = JSON.parse(window.localStorage.getItem(key));
            if (new Date().getTime() > item.expires) {
              // console.log(key, item);
              window.localStorage.removeItem(key);
            }
          } catch (e) {

          }

        }
      }
    }
  }catch (e){

  }
}

function currentTime() {
  let timeStamp = (new Date()).valueOf();

  return timeStamp
}

function randomNumber(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

export function WSSendId() {
  let timeStamp = currentTime();
  let randId = randomNumber(100000, 999999);
  let id = timeStamp + "-" + randId;
  return id
}

export function generateTag() {
  return randomTag;
}

export function getEncryptInfo() {
  let result = {...window?._encrypt_info, ...queryString.parse(location.search)};
  result.timeStamp ? (result.timestamp = result.timeStamp) : void 0;
  //推推appid与知客机器人id冲突
  result?.appid && delete result.appid;
  return result;
}


export function loadScript(url, timeout = 5000) {
  return new Promise((resolve, reject) => {
    // Adding the script tag to the head as suggested before
    let head = document.head;
    let script = document.createElement('script');
    script.type = 'text/javascript';
    console.log('url',url);
    script.src = url;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    // script.onreadystatechange = callback;
    // script.onload = callback;
    script.onreadystatechange = () => {
      resolve(true);
    }
    script.onload = () => {
      resolve(true);
    }
    setTimeout(() => {
      reject();
    }, timeout);
    script.onerror = () => {
      reject();
    }

    // Fire the loading
    head.appendChild(script);
  });

}


export function checkDarkMode() {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return true;
  } else {
    return false;
  }
}

export function setBrowserIcon(url) {
  try {
    let iconList = document.querySelectorAll('link[rel*="icon"]');
    iconList && iconList.forEach(a => a.setAttribute('href', url));
  } catch (e) {

  }
}


export function array2Obj(obj) {
  if(typeof obj === 'object' && !Array.isArray(obj)){
    return obj;
  }
  let result = {};
  let arr = [];
  try{
    if(typeof obj === 'string'){
      arr =JSON.parse(obj);
    }else{
      arr = obj;
    }
    arr.forEach(i=>{
      // console.log(i)
      if(i.Key && i.Value){
        result[i.Key] = i.Value;
      }
    });
  }catch (e){
  }
  return result;
}