import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Input, Button, message, Tag, Spin} from 'antd';
import {Toast} from 'antd-mobile';
import styles from './orderReply.less';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import request from '../../static/util/request';
import {verifyOrderId, verifyOrderRelyEMail} from '../../services/index';
import * as xssFilters from 'xss';

import {FILE_LIMIT, IMAGE_LIMIT} from '../../static/const';

const OrderReply = (
  props
) => {
  const {isMobile} = props;

  const {id} = useParams();
  const [init, setInit] = useState(false);
  const [error, setError] = useState(false);
  const [replySuccess, setReplySuccess] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [validateSuccess, setValidateSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [attas, setAttas] = useState([]);
  const [content, setContent] = useState(BraftEditor.createEditorState(null));
  const [contentMobile, setContentMobile] = useState('');
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [replyTime, setReplyTime] = useState(null);


  useEffect(() => {
    document.title = '回复工单';
    if (!id) {
      setError(true);
      setInit(true);
    } else {
      (async function () {
        try {
          const res = await verifyOrderId({task_id: id});
          if (res.errno === 0 && res?.data?.task_id) {
            setOrderId(res?.data?.task_id);
          } else {
            setError(true);
            setOrderId(id);
          }
          setInit(true);
        } catch (e) {
          setError(true);
          setInit(true);
        }


        setInit(true);
      })();
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      loading === true ? Toast.show('', 0, null, true) : Toast.clear();
    }
  }, [loading]);

  const uploadFn = (param) => {
    const serverURL = 'v1/task/uploadAtta';
    return new Promise((resolve) => {
      request(serverURL, {
        method: 'POST',
        'Content-Type': 'multipart/form-data',
        file: param.file,
      }).then(res => {
        param.success && param.success({
          url: res?.data?.file_url,
          meta: {
            id: '',
            title: '',
            alt: '',
            loop: true, // 指定音视频是否循环播放
            autoPlay: true, // 指定音视频是否自动播放
            controls: true, // 指定音视频是否显示控制栏
            poster: '', // 指定视频播放器的封面
          },
        });
        resolve(res);
      });
    });

    return;
  };


  const toastHandle = (msg) => {
    isMobile ? Toast.show(msg) : message.warn(msg);
  }

  const toastFailHandle = (msg)=>{
    isMobile ? Toast.show({
      icon: 'fail',
      content: msg,
    }) : message.error(msg);
  }

  const uploadHandle = async (files) => {
    let fileSize = files[0].size;
    if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(files[0].name)) {
      if (fileSize > IMAGE_LIMIT) {
        return toastHandle('上传文件过大(>5mb)，请重新选择');
      }
    }
    if (fileSize > FILE_LIMIT) {
      return toastHandle('上传文件过大(>20mb)，请重新选择');
    }

    setLoading(true);
    try {
      const res = await uploadFn({file: files[0]});
      // console.log(res);
      if (res.errno === 0 && res.data) {
        setAttas(prev => {
          return [res.data, ...prev];
        })
      }
      setLoading(false);
    } catch (e) {
      return toastHandle('上传失败，请重试');
      setLoading(false);
    }


  };

  const fileInputOnChange = (e) => {
    const files = e.target && e.target.files;
    files && files.length && uploadHandle && uploadHandle(files);
    e.target.value = '';
  }

  const fileInputClickHandler = () => {
    inputRef.current && inputRef.current.click();
  }

  const validateHandle = async () => {
    if (!email) {
      return;
    }
    if (!/^[\w\-]+@[a-zA-Z\d\-]+(\.[a-zA-Z]{2,8}){1,2}$/ig.test(email)) {
      toastHandle('请输入正确格式');
      return;
    }
    try {
      const res = await verifyOrderRelyEMail({task_id: orderId, email: email,reply_id:id});
      if (res.errno === 0) {
        setValidateSuccess(true);
      } else {
        toastFailHandle('身份验证失败，请检查后重新验证')
        // Toast.fail('身份验证失败，请检查后重新验证');
      }
    } catch (e) {
      toastFailHandle('身份验证失败，请检查后重新验证')
      // Toast.fail('身份验证失败，请检查后重新验证');
    }

    // verifyOrderRelyEMail
  }

  const deleteAttaHandle = index => {
    setAttas(prev => {
      let temp = [...prev];
      temp.splice(index, 1);
      return temp;
    })
  }

  const getContent = () => {
    return isMobile ? contentMobile : content.toHTML();
  }

  const submit = async () => {
    if (isMobile && !contentMobile) {
      toastHandle('内容不能为空');
      return;
    }
    if (!isMobile && !content.toText()) {
      toastHandle('内容不能为空');
      return;
    }

    if (!/^[\w\-]+@[a-zA-Z\d\-]+(\.[a-zA-Z]{2,8}){1,2}$/ig.test(email)) {
      toastHandle('请输入正确的邮箱格式');
      return;
    }

    let params = {task_id: orderId, email: email, reply_attas: attas,reply_id:id};
    params.content = getContent();

    try {
      const res = await verifyOrderRelyEMail(params);
      if (res.errno === 0) {
        setReplySuccess(true);
      } else {
        toastFailHandle(res.errmsg || '回复失败');
        // Toast.fail(res.errmsg || '回复失败');
      }
    } catch (e) {
      toastFailHandle( '回复失败，请稍后重试');
      // Toast.fail('回复失败，请稍后重试');
    }
  }

  const controls = [
    'text-color',
    'bold',
    'underline',
    'separator',
    'text-align',
    'separator',
    'list-ol',
    'separator',
    'link',
    'separator',
    'separator',
    'fullscreen',
    'media',
  ];

  const renderReply = () => {
    return (
      <Spin spinning={loading}>
        <div className={styles['reply-container']}>
          <Card bordered={false} className={styles['card']}>
            <div className={styles['header']}>
              <img width={116} height={26} src={"https://p0.ssl.qhimg.com/t010aee4a4796337f5e.png"}/>
            </div>

            <div className={styles['order-id']}>工单ID：{orderId}</div>

            <div className={styles['form-item']}>
              <div className={styles['label']}>身份验证：</div>
              <Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                className={styles['Input']}
                placeholder={'请输入收到工单消息的邮件的邮箱地址'}
              />
            </div>

            <div className={styles['form-item']}>
              <div className={styles['label']} style={{verticalAlign: 'top'}}>
                工单回复：
              </div>
              <BraftEditor
                className={styles['braft-editor-container']}
                controls={controls}
                onChange={(e) => {
                  setContent(e)
                }}
                media={{
                  uploadFn: uploadFn,
                }}
                value={content}
              />

              <input
                onChange={fileInputOnChange}
                ref={inputRef} type="file" accept={'*'} style={{display: 'none'}}/>
              <div className={styles['with-out-label']} style={{lineHeight: '28px'}}>
                {
                  attas && attas.map((item, index) => {
                    return <Tag color={"blue"} closable={true} onClose={() => {
                      deleteAttaHandle(index)
                    }} key={item.file_url}>{item.file_name}</Tag>
                  })
                }
                <a onClick={() => {
                  fileInputClickHandler()
                }}>上传附件</a>
              </div>
            </div>

            <div className={styles['form-item']}>
              <div className={styles['with-out-label']}>
                <Button onClick={() => {
                  submit()
                }} className={styles['btn']} type={'primary'}>
                  提交
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Spin>

    );
  };

  const renderSuccess = () => {
    return (
      <div className={styles['reply-container']}>
        <Card bordered={false} className={styles['card']}>
          <div className={styles['header']}>
            <img width={116} height={26} src={"https://p0.ssl.qhimg.com/t010aee4a4796337f5e.png"}/>
          </div>
          <div className={styles['order-id']} style={{marginBottom: 40}}>
            工单ID：{orderId}
          </div>
          <div className={styles['success-container']}>
            <img className={`${styles['success-icon']}`} width={72} height={72}
                 src={"https://p3.ssl.qhimg.com/t0153e012211a0ff802.png"}/>
            <div className={styles['success-label']}>已提交成功</div>
          </div>

          <div className={styles['content-container']}>
            <div className={styles['content-card']}>
              <div className={styles['header']}>
                <div className={styles['time-container']}>
                  <span className={styles['label']}>我的回复</span>
                  {replyTime ? <span className={styles['time']}>{replyTime}</span> : null}
                </div>
                {attas && attas.length ? <div className={styles['attachment-container']}>
                  {
                    attas && attas.map((item, index) => {
                      return <a href={item.file_url} target="_blank" key={item.file_url}>{item.file_name}</a>
                    })
                  }
                </div> : null}
              </div>

              <div className={styles['content']} dangerouslySetInnerHTML={{__html: getContent()}}>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className={styles['reply-container']}>
        <Card bordered={false} className={styles['card']}>
          <div className={styles['header']}>
            <img width={116} height={26} src={"https://p0.ssl.qhimg.com/t010aee4a4796337f5e.png"}/>
          </div>

          <div className={styles['error-container']}>
            <svg
              style={{width: 72, height: 72, marginTop: 67, marginBottom: 16}}
            >
              <use xlinkHref={`#icon-zhike-yangshi_icon_face_frown`}/>
            </svg>
            <p className={styles['label']}>抱歉，您的地址无效</p>
            <p className={styles['desc']}>请检查后重新访问</p>
          </div>
        </Card>
      </div>
    );
  };

  const render = () => {
    return <div className={styles['order-reply-page']}>{
      error ? renderError() : (replySuccess ? renderSuccess() : renderReply())
      // return error ? renderErrorMobile() : (renderReplyMobile());
    }</div>;
  };

  const renderValidateMobile = () => {
    return (
      <div className={styles['order-reply-page-mobile']}>
        <img
          className={styles['logo']}
          src="https://p0.ssl.qhimg.com/t010aee4a4796337f5e.png"
        />
        <div className={styles['id']}>工单ID：{orderId}</div>
        <div className={styles['validate-label']}>
          回复前需验证身份，请输入收到工单消息的邮件地址
        </div>

        <input
          className={styles['validate-input']}
          placeholder={'请输入收到工单消息的邮件地址'}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />
        <div onClick={() => {
          validateHandle()
        }} className={`${styles['btn']} ${email ? '' : styles['-disable']}`}>提交
        </div>
      </div>
    );
  };

  const renderReplyMobile = () => {
    return (
      <div className={styles['order-reply-page-mobile']}>
        <img
          className={styles['logo']}
          src="https://p0.ssl.qhimg.com/t010aee4a4796337f5e.png"
        />
        <div className={styles['id']}>工单ID：{orderId}</div>
        <div className={styles['reply-title']}>回复工单</div>
        <textarea placeholder={''} value={contentMobile} onChange={(e) => {
          setContentMobile(e.target.value)
        }} className={styles['reply-input']}></textarea>
        <input
          onChange={fileInputOnChange}
          ref={inputRef} type="file" accept={'*'} style={{display: 'none'}}/>

        {
          attas ? <div className={styles['attachment-container']}>

            {
              attas && attas.map((item, index) => {
                return <div key={item.file_url} className={styles['item']}>{item.file_name}</div>
              })
            }
          </div> : null
        }

        <div>
          <a onClick={() => {
            fileInputClickHandler()
          }}>上传附件</a>
        </div>

        <div className={styles['footer']}>
          <div className={styles['btn']} onClick={() => {
            submit()
          }}>提交
          </div>
        </div>
      </div>
    );
  };

  const renderSuccessMobile = () => {
    return (
      <div className={styles['order-reply-page-mobile']}>
        <img
          className={styles['logo']}
          src="https://p0.ssl.qhimg.com/t010aee4a4796337f5e.png"
        />
        <div className={styles['id']}>工单ID：{orderId}</div>
        <div className={styles['success-container']}>
          <img
            className={styles['success-icon']}
            src="https://p3.ssl.qhimg.com/t0153e012211a0ff802.png"
          />
          <div className={styles['success-label']}>已提交成功</div>
        </div>

        <div className={styles['content-container']}>
          <div className={styles['content']}>
            {contentMobile}
          </div>
          {
            attas ? <div className={styles['attachment-container']}>

              {
                attas && attas.map((item, index) => {
                  return <div key={item.file_url} className={styles['item']}>{item.file_name}</div>
                })
              }
            </div> : null
          }

          {replyTime ? <div className={styles['time']}>{replyTime}</div> : null}
        </div>
      </div>
    );
  };

  const renderErrorMobile = () => {
    return (
      <div className={styles['order-reply-page-mobile']}>
        <img
          className={styles['logo']}
          src="https://p0.ssl.qhimg.com/t010aee4a4796337f5e.png"
        />
        <div style={{textAlign: 'center'}}>
          <svg
            style={{
              width: '.44rem',
              height: '.44rem',
              marginTop: '1.1rem',
              marginBottom: '.16rem',
            }}
          >
            <use xlinkHref={`#icon-zhike-yangshi_icon_face_frown`}/>
          </svg>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return error ? renderErrorMobile() : (replySuccess ? renderSuccessMobile() : (validateSuccess ? renderReplyMobile() : renderValidateMobile()));
  };

  return init ? (isMobile ? renderMobile() : render()) : null;
};

export default (OrderReply);
