import React, {useState} from 'react';
import styles from './index.less';
import {Form, Input, Select, Button, Spin, Card, Radio, Tooltip, Checkbox} from 'antd';
import {List, InputItem, WhiteSpace} from 'antd-mobile';

import {submitClue} from '../../services';
import {submitBusClue} from '../../services';
import {generateWid} from "../../static/util/utils";
import {hasCollectedClues} from '../../static/util/store';

const CluesCollect = props => {

  const {
    isMobile,
    submit = () => {

    },
    options = {},
  } = props;


  const [formName, setFormName] = useState(new Date().getTime());

  const [form] = Form.useForm();

  const {
    regData = [], finishHandle = () => {
    }, token, isIMConnect,
  } = options;
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('normal');
  const [errorMsg, setErrorMsg] = useState('');

  const formItemLayout = {
    labelCol: {
      xs: {span: 6},
      sm: {span: 6},
    },
    wrapperCol: {
      xs: {span: 17},
      sm: {span: 17},
    },
  };

  const phoneValidator = (rule, value) => {
    if (!value || value === '' || value.trim() === '') {
      return Promise.reject('请输入手机号');
    }

    if (!/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,1,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(value)) {
      return Promise.reject('请输入正确手机号');
    }
    return Promise.resolve();
  };

  const companyNameValidator = (rule, value) => {
    if (!value || value === '' || value.trim() === '') {
      return Promise.reject('请输入公司名称');
    }

    if (!(value?.length && value?.length >= 6)) {
      return Promise.reject('最少输入6个字符');
    }

    if (/^[0-9]*$/.test(value)) {
      return Promise.reject('公司名称不能全部为数字');
    }
    if (/^[A-Za-z]+$/.test(value)) {
      return Promise.reject('公司名称中不能全部为字母');
    }


    return Promise.resolve();
  };


  const provChangeHandle = (e) => {
    let prov = regData.find(item => item.code === e);
    setCityList(prov?.citys || []);
    form.setFieldsValue({
      city: ''
    })
  }

  const onFinish = async (values) => {

    if (Array.isArray(values?.service)) {
      values.service = `[${values.service.join(',')}]`;
    }

    if (!isIMConnect) {
      try {
        setLoading(true);
        let res = await submitClue(values);
        if (res?.errno === 0) {
          setStatus('success');
          let wid = generateWid();
          hasCollectedClues(wid, true);
          finishHandle();
        } else {
          setStatus('error');
          setErrorMsg(res?.errmsg);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    } else {
      try {
        let params = {...values};
        params.serviceToken = token;
        let res = await submitBusClue(params);
        console.log(res);
        if (res?.code === 200 && res?.msg === 'Success') {
          setStatus('success');
          let wid = generateWid();
          hasCollectedClues(wid, true);
          finishHandle();
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }

  }

  // const render = () => {
  //   return <div className={styles['container']}>
  //     <Spin spinning={loading} size={"small"}>
  //       <div className={styles['content']}>
  //         {status === 'success' ? null : <div className={styles['title']}>快速申请服务</div>}
  //         <div className={styles['desc']}>
  //           {status === 'success' ? '您的申请已提交成功，营销顾问会在1个工作日之内与您联系，请您注意接听电话。' :
  //             '请留下您的联系信息，我们会安排专业的营销顾问在1个工作日内与您联系，一对一为您详细介绍360的推广服务及产品，助力您挖掘无限商机~'}
  //         </div>
  //         <Form className={styles['form']} {...formItemLayout} form={form} name={`cluesForm-${formName}`}
  //               onFinish={onFinish}>
  //           <Form.Item
  //             label="姓名"
  //             name="username"
  //             labelAlign="left"
  //             rules={[
  //               {
  //                 required: status === 'success' ? false : true,
  //                 message: '请输入姓名',
  //               },
  //             ]}
  //           >
  //             <Input size="small" placeholder="请输入姓名"/>
  //           </Form.Item>
  //           <Form.Item
  //             label="手机号"
  //             name="phone"
  //             labelAlign="left"
  //             rules={[
  //               {
  //                 required: status === 'success' ? false : true,
  //                 validator: phoneValidator
  //               },
  //             ]}
  //             validateTrigger="onSubmit"
  //           >
  //             {status === 'success' ? form.getFieldValue('phone') : <Input size="small" placeholder="请输入手机号"/>}
  //           </Form.Item>
  //           <Form.Item
  //             label="公司名称"
  //             name="company"
  //             labelAlign="left"
  //             rules={[
  //               {
  //                 required: status === 'success' ? false : true,
  //                 validator: companyNameValidator
  //               },
  //
  //             ]}
  //             validateTrigger="onSubmit"
  //           >
  //             <Input size="small" placeholder="请输入公司名称"/>
  //           </Form.Item>
  //           <Form.Item
  //             label="省份"
  //             name="province"
  //             labelAlign="left"
  //             initialValue={""}
  //             rules={[
  //               {
  //                 required: status === 'success' ? false : true,
  //                 message: '请选择省份',
  //               },
  //             ]}
  //           >
  //             <Select size="small" onChange={provChangeHandle}>
  //               <Select.Option value="">
  //                 请选择省份
  //               </Select.Option>
  //               {regData.map(v => {
  //                 return (
  //                   <Select.Option value={v.code} key={v.code}>
  //                     {v.name}
  //                   </Select.Option>
  //                 );
  //               })}
  //             </Select>
  //           </Form.Item>
  //           <Form.Item
  //             label="城市"
  //             name="city"
  //             labelAlign="left"
  //             initialValue={""}
  //             rules={[
  //               {
  //                 required: status === 'success' ? false : true,
  //                 message: '请选择城市',
  //               },
  //             ]}
  //           >
  //             <Select size="small">
  //               <Select.Option value="">
  //                 请选择城市
  //               </Select.Option>
  //               {cityList.map(v => {
  //                 return (
  //                   <Select.Option value={v.code} key={v.code}>
  //                     {v.name}
  //                   </Select.Option>
  //                 );
  //               })}
  //             </Select>
  //           </Form.Item>
  //
  //           <Button size={"small"} style={{marginTop: '4px'}} type="primary" htmlType="submit">
  //             提交
  //           </Button>
  //         </Form>
  //       </div>
  //     </Spin>
  //   </div>
  //
  // }

  const render = () => {
    return <div className={`${styles['container']} ${isMobile ? styles['-mobile'] : ''}`}>
      <Spin spinning={loading} size={"small"}>

        <div className={styles['content']}>
          {status === 'success' ? null : <div className={styles['title']}>快速申请服务</div>}
          <div className={styles['desc']}>
            {status === 'success' ? '您的申请已提交成功，营销顾问会在2个工作日之内与您联系，请您注意接听电话。' :
              '360智慧商业打造共生共长的智慧商业生态，请您填写信息，专属顾问会在2个工作日内联系您。'}
          </div>

          <Form className={styles['form']} {...formItemLayout} form={form} name={`cluesForm-${formName}`}

                onFinish={onFinish}>

            <Form.Item
              {...formItemLayout}
              label="申请服务"
              name="service"
              labelAlign="left"
              valuePropName="checked"
              rules={[
                {
                  required: status === 'success' ? false : true,
                  message: '申请服务',
                },
              ]}
            >
              <Checkbox.Group disabled={status === 'success'}>
                <Checkbox value={1} style={{position: 'relative', paddingRight: '8px', marginRight: '12px'}}>
                  <span>360推广</span>
                  <Tooltip
                    title="360推广：基于安全、精准、可信赖的新一代搜索引擎360搜索，通过关键词技术匹配，定位目标网民，精准展现企业推广信息。包含搜索推广、移动推广、展示广告等。通过专业数据处理算法实现精准定向、成本可控及收益提升。"
                    placement="bottom">
                    <svg className={`icon ${styles['question-icon']}`} aria-hidden="true">
                      <use xlinkHref={`#icon-zhike-question-circle-fill`}/>
                    </svg>
                  </Tooltip>

                </Checkbox>
                <Checkbox value={2} style={{position: 'relative', paddingRight: '8px', marginRight: '12px'}}>
                  <span>企业服务</span>
                  <Tooltip
                    title="企业服务：360企业服务致力于为企业提供全链路式营销解决方案，提供不同领域的SaaS产品/服务，产品涵盖企业营销增值（营销获客/线索触达/销售管理系统）、数据资产安全、企业办公/管理工具、企业基础服务等，多方位驱动企业生意增长。"
                    placement="bottom">
                    <svg className={`icon ${styles['question-icon']}`} aria-hidden="true">
                      <use xlinkHref={`#icon-zhike-question-circle-fill`}/>
                    </svg>
                  </Tooltip>
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="姓名"
              name="username"
              labelAlign="left"
              rules={[
                {
                  required: status === 'success' ? false : true,
                  validator: (rule, value) => {
                    if (!value || value === '' || value.trim() === '') {
                      return Promise.reject('请输入姓名');
                    }
                    return Promise.resolve();
                  }
                },
              ]}
            >
              {status === 'success' ? form.getFieldValue('username') : <Input size="small" placeholder="请输入姓名"/>}

            </Form.Item>
            <Form.Item
              label="手机号"
              name="phone"
              labelAlign="left"
              rules={[
                {
                  required: status === 'success' ? false : true,
                  validator: phoneValidator
                },
              ]}
              validateTrigger="onSubmit"
            >
              {status === 'success' ? form.getFieldValue('phone') : <Input size="small" placeholder="请输入手机号"/>}
            </Form.Item>
            <Form.Item
              label="公司名称"
              name="company"
              labelAlign="left"
              rules={[
                {
                  required: status === 'success' ? false : true,
                  validator: companyNameValidator
                },

              ]}
              validateTrigger="onSubmit"
            >
              {status === 'success' ? form.getFieldValue('company') : <Input size="small" placeholder="请输入公司名称"/>}

            </Form.Item>
            <Form.Item
              label="省份"
              name="province"
              labelAlign="left"
              initialValue={""}
              rules={[
                {
                  required: status === 'success' ? false : true,
                  message: '请选择省份',
                },
              ]}
            >
              {status === 'success' ? regData.find(i => i.code === form.getFieldValue('province'))?.name :
                <Select size="small" onChange={provChangeHandle}>
                  <Select.Option value="">
                    请选择省份
                  </Select.Option>
                  {regData.map(v => {
                    return (
                      <Select.Option value={v.code} key={v.code}>
                        {v.name}
                      </Select.Option>
                    );
                  })}
                </Select>}
            </Form.Item>
            <Form.Item
              label="城市"
              name="city"
              labelAlign="left"
              initialValue={""}
              rules={[
                {
                  required: status === 'success' ? false : true,
                  message: '请选择城市',
                },
              ]}
            >
              {status === 'success' ? cityList.find(i => i.code === form.getFieldValue('city'))?.name :
                <Select size="small">
                  <Select.Option value="">
                    请选择城市
                  </Select.Option>
                  {cityList.map(v => {
                    return (
                      <Select.Option value={v.code} key={v.code}>
                        {v.name}
                      </Select.Option>
                    );
                  })}
                </Select>}
            </Form.Item>
            {status === 'success' ? null :
              <Button className={styles['submit-btn']} size={"small"} type="primary" htmlType="submit">
                提交
              </Button>}
          </Form>

        </div>
      </Spin>
    </div>
  }

  const renderError = () => {
    return <div className={`${styles['error-container']} ${isMobile ? styles['-mobile'] : ''}`}>
      {errorMsg ? errorMsg : '非常抱歉，提交失败，请重新提交。'}
    </div>
  }

  return (
    status === 'error' ? renderError() : render()
  )
}

export default CluesCollect;
