export default function radarLog(Info, event) {
  try {
    let params = { ...Info };
    if (window?.RADAR_MONITOR) {
      window?.RADAR_MONITOR?.log(params, event || 'click');
    }
  } catch (err) {
    console.log(err);
  }
}
