import React, {useContext, useEffect, useState} from 'react';
import H5Modal from "./H5Modal";
import SDKModal from "./SDKModal";
import PCModal from "./PCModal";
import {loadScript} from "../../static/util/utils";
import {getMarketingCollector, getInfo} from "../../services";
import radarLog from "../../static/util/radar";

const getFormInfo = async (rid) => {
  let params = {
    rid
  };//转发全部url参数
  let scriptRes;
  try {
    scriptRes = await loadScript(`https://pub-shbt.s3.360.cn/zhike-admin/robot_${rid}_marketing_collector.conf.js?_t=${new Date().getTime()}`);
  } catch (e) {
  }
  let infoRes;
  if (!(scriptRes && window?.__ZKSDK_MARKETING_COLLECTOR_CONFIG)) {
    infoRes = await getMarketingCollector(params);
  }
  let Info = {};
  let JsonInfo;
  if (window?.__ZKSDK_MARKETING_COLLECTOR_CONFIG) {
    JsonInfo = window?.__ZKSDK_MARKETING_COLLECTOR_CONFIG;
    Info = JsonInfo;
  } else {
    Info = infoRes?.data;
  }

  return Info || null;
}

const getEntrance = (data) => {
  let result = null;
  try {
    result = data.find(item => (item?.entrance && item.entrance?.switch === 1 && item.entrance?.name));
  } catch (e) {

  }
  return result;
}

const getPop = (data, pop) => {
  let result = null;
  try {
    result = data.find(item => item?.pop?.indexOf(pop) >= 0);
  } catch (e) {

  }
  return result;
}

const FormModal = (props) => {
  const {
    isSDK = false,
    isMobile = false,
    rid,
    visible = false,
    formInfo = {},
    onFinishHandle = () => {

    },
    onCloseHandle = () => {

    },
  } = props;

  useEffect(() => {
    if(visible === true){
      console.log('打开表单');
      let c = `risk_cancel_openpcpop`;
      isMobile && (c = `risk_cancel_openpcsdk`);
      isSDK && (c = `risk_cancel_openh5`);
      radarLog({c: c});
    }
  }, [visible]);
  const [formData, setFormData] = useState(null);
  const {name, description} = formInfo || {};
  // useEffect(() => {
  //   if(rid){
  //     getFormInfo(rid);
  //   }
  // }, [rid]);

  useEffect(() => {
    if (formInfo) {
      try {
        let data = JSON.parse(formInfo.form);
        setFormData(data);
        console.log('formData', data);
      } catch (e) {

      }
    }
  }, [formInfo]);


  const {} = props;
  return <>
    {visible ? <>
      {isMobile ? <H5Modal
        onFinishHandle={(data) => {
          radarLog({c: `risk_cancel_submissionh5`});
          onFinishHandle(data, formInfo);
        }}
        onCloseHandle={()=>{
          radarLog({c: `risk_cancel_unfillh5`});
          onCloseHandle();
        }}
        formData={formData} name={name} description={description}/> : <>
        {
          isSDK ? <SDKModal
            onFinishHandle={(data) => {
              radarLog({c: `risk_cancel_submissionpcsdk`});
              onFinishHandle(data, formInfo);
            }}
            onCloseHandle={()=>{
              radarLog({c: `risk_cancel_unfillpcsdk`});
              onCloseHandle();
            }}
            formData={formData} name={name} description={description}/> : <PCModal
            onFinishHandle={(data) => {
              radarLog({c: `risk_cancel_submissionpcpop`});
              onFinishHandle(data, formInfo);
            }}
            onCloseHandle={()=>{
              radarLog({c: `risk_cancel_unfillpcpop`});
              onCloseHandle();
            }}
            formData={formData}
            name={name}
            description={description}/>
        }
      </>}
    </> : null}
  </>
}


export default FormModal;

export {
  getFormInfo,
  getEntrance,
  getPop
}