//状态机，用于确定当前状态及对应的操作，返回对话信息

import React from 'react';
import ChatFileItem from "../components/ChatFileItem";
import addImgTarget from "../static/util/checkImgUrl";
import queryString from "query-string";
import {convertOrderList} from "../userCenter/processControl";
import moment from 'moment-timezone';
import {array2Obj} from "../static/util/utils";

moment.tz.setDefault('Aisa/Shanghai');
const rid = queryString.parse(location.search).rid;

const isGameRobot = rid === '6b4ac81a-0113-493a-aa8c-2a301549242b' ||
  rid === 'c1ae0d32-bcc1-4855-b937-886783ce6423';

let initWelcomQuestion = (
  <div>请输入正确的【游戏名称】后咨询；<br/>如咨询：充值、账号、登陆、游戏大厅、积分商城 等问题，请输入【通用】后咨询。</div>) // 初始状态的欢迎语句， 用于资讯其他游戏时

export let current = 'init';

export function changeCurrent(c) {
  current = c;
}

const sortByMsgTime = (dialogAdd, state) => {
  if (dialogAdd && dialogAdd.length === 0) {
    return state;
  }
  let allArr = [
    ...dialogAdd,
    ...state
  ];
  allArr.sort((prev, next) => {
    try {
      // if(moment)
      return moment(prev.msgTime).isBefore(moment(next.msgTime)) ? -1 : 1;
    } catch (e) {

    }
    return 0;
  });
  return allArr;
}

const getMsgTime = () => {
  // console.log(window._time_difference);
  let timestamp = new Date().getTime() + window._time_difference;
  // console.log(moment(1713863350432).format('HH:mm:ss'))
  // console.log(timestamp,typeof timestamp,moment(timestamp).format('YYYY-MM-DD HH:mm:ss'))
  return moment.tz(timestamp, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
}

class defaultDialogConfig {
  type = 'left';
  content = '';
  list = [];
  id = "";
  _id = "";
  msgId = "";
  showFeedback = false;
  innerHtml = false;
  msgTime = getMsgTime();
  loading;
  chatType = 1;
  hideCollapse = true;
  speakerName;
  typedInput;
  uniqueName;
  contentList;
  component;
  componentName;
  componentExtra;
  options;
  file;
  originFile;
  imInfo;
  imageUrl;
  aTarget;
  disable;
  cid;
  robotId;
  gameList;
  showMore;
  msgType;
  canRetry = true;
  answerType;
  renderType;

  constructor(config = {}) {
    const _this = this;
    Object.keys(config).map(key => {
      if (_this.hasOwnProperty(key) && config[key]) {
        _this[key] = config[key];
      }
    });
  }
}

// console.log(new defaultDialogConfig({
//   type: 'right',
//   content: ``,
// }).msgTime);


// const dialogDefault = {
//   type: 'left', // 左侧对话框
//   content: '', // 对话内容
//   list: [], // 关联问题
//   id: '', // 答案点赞反馈时的回传id
//   showFeedback: false,
//   innerHtml: false, // 以富文本展示
//   _msgTime: '',
//   aTarget: true,
//
//   get msgTime() {
//     return this._msgTime;
//   },
//   set msgTime(value) {
//     this._msgTime = value;
//   }
// }

function reducer(state, action) {
  try {
    let dialogAdd = []
    if (!isGameRobot) { // 通用流程
      switch (action.type) {
        case 'init': // 进入页面初始化状态
          current = "waitQuestion"
          if (action.param.content) {
            dialogAdd = [new defaultDialogConfig({
              content: action.param.content,
              innerHtml: true, // 富文本展示
              hideCollapse: true,
              msgTime: action?.param?.msgTime || getMsgTime(),
            })]
            // dialogAdd = [Object.assign({}, dialogDefault, {
            //   content: action.param.content,
            //   innerHtml: true, // 富文本展示
            //   hideCollapse: true,
            //   msgTime: action?.param?.msgTime || getMsgTime(),
            // })]
          }

          if (action.commons) {
            if (action.commons && action.commons.list && action.commons.list.length > 0) {
              dialogAdd = [
                ...dialogAdd,
                new defaultDialogConfig({
                  content: action.commons.content,
                  list: [...action.commons.list],
                  hideCollapse: true,
                })
              ]
            }
          }

          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'waitQuestion': //  等待输入通用相关问题，并处理输入的问题
          current = "waitResponse"
          dialogAdd = [new defaultDialogConfig({
            type: 'right',
            id: action?.id,
            msgTime: action?.param?.msgTime || getMsgTime(),
            content: `${action.param}`
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'dealResponse': // 显示答案
          current = "waitQuestion";
          if (action.param.msgId) {
            let alIndex = state.findIndex(item => (item.msgId || item.msg_id) === action.param.msgId);
            if (alIndex >= 0) {
              return state;
            }
          }
          console.log('array2Obj(action?.param?.message)', array2Obj(action?.param?.message));
          dialogAdd = [new defaultDialogConfig({
            typedInput: action.param?.typedInput,
            uniqueName: action.param?.uniqueName,
            content: action.param.content,
            contentList: action.param.contentList,
            component: action.param.component,
            componentName: action.param.componentName,
            options: action.param.options,
            file: action.param.file,
            showFeedback: true,
            hideCollapse: action.param.hideCollapse,
            id: action.param.id,
            _id: action.param._id,
            innerHtml: true, // 富文本展示
            imInfo: action.param.imInfo,
            aTarget: action.param.aTarget,
            msgId: action.param.msgId,
            msgTime: action?.param?.msgTime,
            imageUrl: action.param.imageUrl,
            msgType: action.param.msgType,
            type: parseInt(action.param?.speakerType || action.param?.speaker_type) !== 1 ? 'left' : 'right',
            originFile: array2Obj(action?.param?.message)
          })]
          if (action.param && action.param.list && action.param.list.length > 0) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                content: action.param.commons || '亲爱的用户您好，请问您是否想咨询以下问题：',
                list: [...action.param.list]
              })
            ]
          }
          if (action.param.msgTime) {
            // console.log('带有时间的需排序');
            let maxLastIndex = state.findLastIndex(item => {
              try {
                return new Date(item.msgTime) > new Date(action.param.msgTime);
              } catch (e) {

              }
            });
            let minLastIndex = state.findLastIndex(item => {
              try {
                return new Date(item.msgTime) <= new Date(action.param.msgTime);
              } catch (e) {

              }
            }) || 0;
            let temp = [...state];
            if (minLastIndex >= 0) {
              temp.splice(minLastIndex + 1, 0, ...dialogAdd);
            } else if (maxLastIndex >= 0) {
              temp = [...dialogAdd, ...temp];
            } else {
              temp = [...temp, ...dialogAdd];
            }

            return temp;
          }
          return [
            ...state,
            ...dialogAdd
          ]
        case 'dealResponseWithList':
          current = "waitQuestion"
          dialogAdd = new defaultDialogConfig({
            typedInput: action.param?.typedInput,
            content: action.param.content,
            msgTime: action?.param?.msgTime,
            contentList: action.param.contentList,
            component: action.param.component,
            componentName: action.param.componentName,
            options: action.param.options,
            file: action.param.file,
            showFeedback: true,
            id: action.param.id,
            innerHtml: true, // 富文本展示
            imInfo: action.param.imInfo,
            aTarget: action.param.aTarget,
            msgId: action.param.msgId,
            msgType: action.param.msgType,
          })

          if (action.param && action.param.list && action.param.list.length > 0) {
            dialogAdd.list = [...action.param.list];
          }
          dialogAdd = [dialogAdd];
          return [
            ...state,
            ...dialogAdd
          ]
        case 'guessResponse':
          current = "waitQuestion"
          if (action.param && action.param.list && Array.isArray(action.param.list)) {
            dialogAdd = [...dialogAdd, new defaultDialogConfig({
              content: action.param.content,
              list: [...action.param.list],
              msgId: action.param.msgId,
              msgTime: action?.param?.msgTime
            })]
          }
          return [
            ...state,
            ...dialogAdd
          ]
        case 'weakText':
          dialogAdd = [new defaultDialogConfig({
            type: 'weakText',
            content: `${action.param}`,
            msgId: action.msgId,
            msgTime: action?.param?.msgTime
          })]
          return [
            ...state,
            ...dialogAdd
          ];
          break;
        case 'sendMsg':
          dialogAdd = [new defaultDialogConfig({
            type: 'right',
            content: `${action.param}`,
            msgTime: action?.param?.msgTime,
            loading: action.loading,
            imSend: action.imSend,
            aTarget: true,
            msgId: action.msgId,
            chatType: action.chatType || 1,
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'sendImg':
          dialogAdd = [new defaultDialogConfig({
            type: action.who ? action.who : 'right',
            content: `${action.param}`,
            msgTime: action?.param?.msgTime,
            innerHtml: true,
            hideCollapse: true,
            loading: action.loading,
            id: action.id,
            msgId: action.msgId,
            msgType: action.msgType,
            imageUrl: action.imageUrl,
            originFile: action.originFile,
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'sendFile':
          dialogAdd = [new defaultDialogConfig({
            type: action.who ? action.who : 'right',
            component: action.component,
            msgTime: action?.param?.msgTime,
            file: action.file,
            hideCollapse: true,
            loading: action.loading,
            id: action.id,
            msgId: action.msgId,
            msgType: action.msgType,
            originFile: action.originFile,
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'update':
          let updateResult = [...state];
          if (action.id != undefined && action.callback) {
            let index = updateResult.findIndex((item) => item.id === action.id);
            updateResult[index] && (updateResult[index] = action.callback(updateResult[index]));
          } else if (action.index != undefined && action.callback) {
            updateResult[action.index] && (updateResult[action.index] = action.callback(updateResult[action.index]));
          } else if (action.msgId != undefined && action.callback) {
            let index = updateResult.findIndex((item) => {
              return (item.msgId === action.msgId || item.id === action.msgId)
            });
            updateResult[index] && (updateResult[index] = action.callback(updateResult[index]));
          }
          return updateResult;
          break;
        case 'sendComponent':
          dialogAdd = [new defaultDialogConfig({
            type: action.who ? action.who : 'right',
            component: action.component,
            msgTime: action?.param?.msgTime,
            file: action.file,
            componentExtra:action.componentExtra,
            hideCollapse: true,
            loading: action.loading,
            id: action.id,
            msgId: action.msgId,
            msgType: action.msgType,
            originFile: action.originFile,
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case  'addNewMessage':

          action.param.list.forEach((item) => {
            if (item.msgId) {
              let alIndex = state.findIndex(i => (i.msgId || i.msg_id) === item.msgId);
              if (alIndex >= 0) {
                return;
              }
            }
            let result = new defaultDialogConfig({
              type: parseInt(item.speakerType || item.speaker_type) !== 1 ? 'left' : 'right',
              innerHtml: true, // 富文本展示
              chatType: item.chatType,
              _id: item._id,
              msgTime: item?.msgTime,
              msgId: item.msgId || item.msg_id || item._id,
              // msgTime: item.msgTime,
              // robotId: item.robotId,
              speakerName: item.speakerName,
            })
            let additionalArr = [];
            let hideResult = false;
            switch (item.msgType) {
              case 3:
                let fileObj = array2Obj(item?.message);
                result.component = ChatFileItem;
                result.file = fileObj
                break;
              case 2:
                let imgObj = array2Obj(item?.message);
                result.content = `<img src="${imgObj.fileUrl}" onerror="this.src='https://p2.ssl.qhimg.com/t017638ac82012a6f59.png'" />`;
                break;
              default:
                if (item?.attach_message_type && Array.isArray(item?.attach_message)) {
                  let commons = '';
                  let tempArr;
                  let disable = false;
                  switch (item.attach_message_type) {
                    case 'recommend':
                      commons = action?.robotInfo?.kfInfo?.guess;
                      break;
                    case 'common':
                      commons = action?.robotInfo?.kfInfo?.commons;
                      break;
                    case 'guess':
                      commons = action?.robotInfo?.kfInfo?.guide;
                      hideResult = true;
                      break;
                    case 'order_list':
                      tempArr = convertOrderList({select_type: 'order', list: item?.attach_message});
                      disable = true;
                      break;
                    case 'dialogue_select':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                      break;
                    case 'order_refund_reason':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                  }
                  tempArr ? (result.list = tempArr , result.disable = disable) : additionalArr.push(new defaultDialogConfig({
                    msgTime: item.msgTime,
                    content: commons,
                    list: [...item?.attach_message]
                  }))
                }
                result.content = addImgTarget(item.message);
                break;
            }

            if (item.sendType === 3002 || item.sendType === 3003) {
              let msg;
              try {
                msg = JSON.parse(item.content || item.message)?.user_tip;
              } catch (e) {
              }
              result.content = msg || item.message;
              result.type = 'weakText';
            }


            dialogAdd = hideResult ? [...dialogAdd, ...additionalArr] : [...dialogAdd, result, ...additionalArr];
            return result;
          });
          return sortByMsgTime(dialogAdd, state);
          break;

        case 'addRecord':

          dialogAdd = action.param.list.map((item) => {
            return new defaultDialogConfig({
              cid: item.cid,
              type: item.sender_type != '0' ? 'left' : 'right',
              content: item.msg,
              msgTime: item?.msgTime || getMsgTime(),
              innerHtml: true, // 富文本展示
            })
          })

          return [
            ...dialogAdd,
            ...state
          ]
          break;
        case 'delete':
          let result = [...state];
          if (action.param) {
            let index = result.findIndex((item) => {
              if (typeof action.param === 'object') {
                return item === action.param;
              }
              return item.msgId === action.param
            });
            if (index >= 0) {
              result.splice(index, 1);
              return result;
            }
          }
          return result;
          break;
        case 'addHistory':
          action.param.list.forEach((item) => {
            if (item.msgId || item.msg_id) {

              let alIndex = state.findIndex(i => {
                let absV = null;
                try {
                  absV = Math.abs(new Date(i.msgTime).getTime() - new Date(item.msgTime).getTime());
                } catch (e) {

                }
                return (i.msgId === item.msgId ||
                  (i.msgId !== item.msgId && i.content === item.message && (i.msgTime === item.msgTime || (absV && absV <= 2000))))
              });
              if (alIndex >= 0) {
                state[alIndex].msgId = item.msgId || item.msg_id;
                state[alIndex]._id = item._id || '';
                state[alIndex].chatType = item.chatType || '';
                return;
              }
            }
            let result = new defaultDialogConfig({
              type: parseInt(item.speakerType || item.speaker_type) !== 1 ? 'left' : 'right',
              innerHtml: true, // 富文本展示
              chatType: item.chatType,
              _id: item._id,
              msgId: item.msgId || item.msg_id || item._id,
              msgTime: item?.msgTime,
              robotId: item.robotId,
              speakerName: item.speakerName,
              contentList: item.ext_answer,
            })
            let additionalArr = [];
            let hideResult = false;
            switch (item.msgType) {
              case 3:
                // result
                // let file;
                // try {
                //   file = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.component = ChatFileItem;
                result.file = item?.message;
                break;
              case 2:
                // let img;
                // try {
                //   img = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.content = `<img src="${item?.message?.fileUrl}" onerror="this.src='https://p2.ssl.qhimg.com/t017638ac82012a6f59.png'" />`;
                break;
              default:
                if (item?.attach_message_type && Array.isArray(item?.attach_message)) {
                  let commons = '';
                  let tempArr;
                  let disable = false;
                  switch (item.attach_message_type) {
                    case 'recommend':
                      commons = action?.robotInfo?.kfInfo?.guess;
                      break;
                    case 'common':
                      // debugger;
                      // commons = action?.robotInfo?.kfInfo?.commons;
                      tempArr = [...item?.attach_message];
                      break;
                    case 'guess':
                      commons = action?.robotInfo?.kfInfo?.guide;
                      hideResult = true;
                      break;
                    case 'order_list':
                      tempArr = convertOrderList({select_type: 'order', list: item?.attach_message});
                      disable = true;
                      break;
                    case 'dialogue_select':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                      break;
                    case 'order_refund_reason':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                    default:
                      tempArr = convertOrderList({list: item?.attach_message});
                      break;
                  }
                  tempArr ? (result.list = tempArr , result.disable = disable) : additionalArr.push(new defaultDialogConfig({
                    msgTime: item.msgTime,
                    content: commons,
                    disable,
                    list: [...item?.attach_message]
                  }))
                }
                result.content = addImgTarget(item.message);
                break;
            }
            // content: item.message,

            if (item.sendType === 3002 || item.sendType === 3003) {
              let msg;
              try {
                msg = JSON.parse(item.content || item.message)?.user_tip;
              } catch (e) {
              }
              result.content = msg || item.message;
              result.type = 'weakText';
            }


            dialogAdd = hideResult ? [...dialogAdd, ...additionalArr] : [...dialogAdd, result, ...additionalArr];
            return result;
          });
          if (action?.param?.init) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                type: 'weakText',
                content: `———— 以上为历史消息 ————`,
                msgTime: action?.param?.msgTime
              })
            ]
          }
          return sortByMsgTime(dialogAdd, state);
          break;
        case 'coverHistory':
          action.param.list.forEach((item) => {
            let result = new defaultDialogConfig({
              type: parseInt(item.speakerType || item.speaker_type) !== 1 ? 'left' : 'right',
              innerHtml: true, // 富文本展示
              chatType: item.chatType,
              _id: item._id,
              msgId: item.msgId || item.msg_id || item._id,
              msgTime: item?.msgTime || getMsgTime(),
              robotId: item.robotId,
              speakerName: item.speakerName,
            })
            // if(item.componentName === ''){
            //
            // }
            let additionalArr = [];
            let hideResult = false;
            switch (item.msgType) {
              case 3:
                // result
                // let file;
                // try {
                //   file = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.component = ChatFileItem;
                result.file = item?.message;
                break;
              case 2:
                // let img;
                // try {
                //   img = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.content = `<img src="${item?.message?.fileUrl}" onerror="this.src='https://p2.ssl.qhimg.com/t017638ac82012a6f59.png'" />`;
                break;
              default:
                if (item?.attach_message_type && Array.isArray(item?.attach_message)) {
                  let commons = '';
                  let tempArr;
                  let disable = false;
                  switch (item.attach_message_type) {
                    case 'recommend':
                      commons = action?.robotInfo?.kfInfo?.guess;
                      break;
                    case 'common':
                      tempArr = [...item?.attach_message];
                      // commons = action?.robotInfo?.kfInfo?.commons;
                      break;
                    case 'guess':
                      commons = action?.robotInfo?.kfInfo?.guide;
                      hideResult = true;
                      break;
                    case 'order_list':
                      tempArr = convertOrderList({select_type: 'order', list: item?.attach_message});
                      disable = true;
                      break;
                    case 'order_refund_reason':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                    default:
                      tempArr = convertOrderList({list: item?.attach_message});
                      break;
                  }
                  tempArr ? (result.list = tempArr , result.disable = disable) : additionalArr.push(
                    new defaultDialogConfig({
                      msgTime: item.msgTime,
                      content: commons,
                      disable,
                      list: [...item?.attach_message]
                    })
                  )
                }
                result.content = addImgTarget(item.message);
                break;
            }
            // content: item.message,

            if (item.sendType === 3002 || item.sendType === 3003) {
              let msg;
              try {
                msg = JSON.parse(item.content || item.message)?.user_tip;
              } catch (e) {
              }
              result.content = msg || item.message;
              result.type = 'weakText';
            }


            dialogAdd = hideResult ? [...dialogAdd, ...additionalArr] : [...dialogAdd, result, ...additionalArr];
            return result;
          });
          if (action?.param?.init) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                type: 'weakText',
                content: `———— 以上为历史消息 ————`,
                msgTime: action?.param?.msgTime
              })
            ]
          }


          return [
            ...dialogAdd,
          ]
          break;
        case 'showConnectIM':
          let imResult = [...state];
          imResult = imResult.map(item => {
            item.showIMBtn = false;
            return item;
          });
          if (action.msgId) {
            let index = imResult.findIndex((item) => item.msgId === action.msgId);
            imResult[index] && (imResult[index].showIMBtn = true) && (imResult[index].prompt = action.prompt);
          }
          return imResult;
          break;
        case 'addGuide':
          if (action.param.content) {
            dialogAdd = [new defaultDialogConfig({
              content: action.param.content,
              msgTime: action?.param?.msgTime || getMsgTime(),
              innerHtml: true, // 富文本展示
              hideCollapse: true,
            })];
          }
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'overwrite':
          if (Array.isArray(action.param)) {
            // debugger;
            let temp = action.param.filter(item => {
              if (!item.content && !item.file) {
                return false;
              }
              item.typedInput = false;
              item.msgTime = item.msgTime || getMsgTime();
              item.showFeedback = false;//历史记录不允许点赞点踩
              if (item && item.file) {
                if (item.uniqueName || item?.file?.uniqueName) {
                  item && item.file && (item.component = ChatFileItem);
                } else {
                  item.component = ChatFileItem;
                  item.loading = 'error';
                  // return false;
                }
              }
              let imgReg = /<img.*?(?:>|\/>)/gi;
              if (item && (item.content && item.content.match && item.content.match(imgReg)) || item.msgType === 2) {
                // console.log(item);
                if (item && item.imageUrl) {
                  item && item.imageUrl && (item.content = `<img src="${item.imageUrl}" />`);
                } else {
                  item.loading && (item.loading = 'error');

                  // return false;
                }

              }
              return true;
            });
            return [...temp];
          }
          break;
        default:
          return [];
          break;
      }
    } else {

      switch (action.type) {
        case 'init': // 进入页面初始化状态
          current = "waitGame"
          if (action.param.content) {
            dialogAdd = [new defaultDialogConfig({
              content: action.param.content,
              msgTime: action?.param?.msgTime,
              innerHtml: true,// 富文本展示
              hideCollapse: true,
            })]
          }
          // dialogAdd = [
          //   ...dialogAdd,
          //   Object.assign({}, dialogDefault, {
          //   content: initWelcomQuestion
          // })]

          if (action.commons) {
            if (action.commons && action.commons.list && action.commons.list.length > 0) {
              dialogAdd = [
                ...dialogAdd,
                new defaultDialogConfig({
                  content: action.commons.content,
                  list: [...action.commons.list],
                  hideCollapse: true,
                })
              ]
            }
          }

          if (action.param.list && action.param.list.length > 0) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                content: '请选择您要咨询的游戏',
                gameList: action.param.list,
                showMore: true,
                hideCollapse: true,
              })
            ]
          } else {
            //没有游戏列表
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                showMore: true,
                hideCollapse: true,
              })
            ]
          }
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'changeGame': // 资讯其他游戏时
          current = "waitGame"
          dialogAdd = [new defaultDialogConfig({
            content: initWelcomQuestion,
            msgTime: action?.param?.msgTime || getMsgTime(),
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'waitGame': // 等待输入游戏名
          current = "waitQuestion"
          dialogAdd = [new defaultDialogConfig({
            type: 'right',
            content: action.param,
            msgTime: action?.param?.msgTime
          }),
            new defaultDialogConfig({
              content: (`<div>请输入您的问题。<br/>【温馨提示】咨询其他游戏请点击${action.isMobile ? '左下角' : '右下角'}“咨询其他游戏”按钮。</div>`)
            })
          ]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'waitQuestion': //  等待输入游戏相关问题，并处理输入的问题
          current = "waitResponse"
          dialogAdd = [new defaultDialogConfig({
            type: 'right',
            id: action?.id,
            content: `${action.param}`,
            msgTime: action?.param?.msgTime
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'dealResponse': // 显示答案
          current = "waitQuestion"
          if (action.param.msgId) {
            let alIndex = state.findIndex(item => (item.msgId || item.msg_id) === action.param.msgId);
            if (alIndex >= 0) {
              return state;
            }
          }
          dialogAdd = [new defaultDialogConfig({
            uniqueName: action.param?.uniqueName,
            content: action.param.content,
            component: action.param.component,
            options: action.param.options,
            file: action.param.file,
            showFeedback: true,
            hideCollapse: action.param.hideCollapse,
            id: action.param.id,
            _id: action.param._id,
            innerHtml: true,// 富文本展示
            imInfo: action.param.imInfo,
            aTarget: action.param.aTarget,
            msgId: action.param.msgId,
            imageUrl: action.param.imageUrl,
            msgTime: action?.param?.msgTime,
            originFile: array2Obj(action?.param?.message)
          })]

          if (action.param && action.param.list && action.param.list.length > 0) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                content: action.param.commons || '亲爱的用户您好，请问您是否想咨询以下游戏问题：',
                list: [...action.param.list],
                showMore: true
              })
            ]
          }
          return [
            ...state,
            ...dialogAdd
          ]
        case 'guessResponse':
          current = "waitQuestion"
          if (action.param && action.param.list && action.param.list.length > 0) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                content: action.param.content,
                msgTime: action?.param?.msgTime,
                list: [...action.param.list],
                msgId: action.param.msgId,
              })
            ]
          }
          return [
            ...state,
            ...dialogAdd
          ]
        case 'weakText':
          dialogAdd = [new defaultDialogConfig({
            type: 'weakText',
            content: `${action.param}`,
            msgId: action.msgId,
            msgTime: action?.param?.msgTime
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'sendMsg':
          dialogAdd = [new defaultDialogConfig({
            type: 'right',
            content: `${action.param}`,
            loading: action.loading,
            imSend: action.imSend,
            aTarget: true,
            msgId: action.msgId,
            msgTime: action?.param?.msgTime
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'sendImg':
          console.log(action.param);
          dialogAdd = [new defaultDialogConfig({
            type: action.who ? action.who : 'right',
            content: `${action.param}`,
            innerHtml: true,
            hideCollapse: true,
            loading: action.loading,
            id: action.id,
            msgId: action.msgId,
            msgType: action.msgType,
            msgTime: action?.param?.msgTime,
            imageUrl: action.imageUrl,
            originFile: action.originFile,
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'sendFile':
          dialogAdd = [new defaultDialogConfig({
            type: action.who ? action.who : 'right',
            component: action.component,
            file: action.file,
            hideCollapse: true,
            loading: action.loading,
            id: action.id,
            msgId: action.msgId,
            msgType: action.msgType,
            msgTime: action?.param?.msgTime,
            originFile: action.originFile,
          })]
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'update':
          let updateResult = [...state];
          if (action.id != undefined && action.callback) {
            let index = updateResult.findIndex((item) => item.id === action.id);
            updateResult[index] && (updateResult[index] = action.callback(updateResult[index]));
          } else if (action.index != undefined && action.callback) {
            updateResult[action.index] && (updateResult[action.index] = action.callback(updateResult[action.index]));
          } else if (action.msgId != undefined && action.callback) {
            let index = updateResult.findIndex((item) => {
              return (item.msgId === action.msgId || item.id === action.msgId)
            });
            updateResult[index] && (updateResult[index] = action.callback(updateResult[index]));
          }
          return updateResult;
          break;
        case  'addNewMessage':
          action.param.list.forEach((item) => {
            if (item.msgId) {
              let alIndex = state.findIndex(i => (i.msgId || i.msg_id) === item.msgId);
              if (alIndex >= 0) {
                return;
              }
            }
            let result = new defaultDialogConfig({
              type: parseInt(item.speakerType || item.speaker_type) !== 1 ? 'left' : 'right',
              innerHtml: true, // 富文本展示
              chatType: item.chatType,
              _id: item._id,
              msgId: item.msgId || item.msg_id || item._id,
              msgTime: action?.param?.msgTime,
              // robotId: item.robotId,
              speakerName: item.speakerName,
            });
            let additionalArr = [];
            let hideResult = false;
            switch (item.msgType) {
              case 3:
                let fileObj = array2Obj(item?.message);
                result.component = ChatFileItem;
                result.file = fileObj
                break;
              case 2:
                let imgObj = array2Obj(item?.message);
                result.content = `<img src="${imgObj.fileUrl}" onerror="this.src='https://p2.ssl.qhimg.com/t017638ac82012a6f59.png'" />`;
                break;
              default:
                if (item?.attach_message_type && Array.isArray(item?.attach_message)) {
                  let commons = '';
                  switch (item.attach_message_type) {
                    case 'recommend':
                      commons = action?.robotInfo?.kfInfo?.guess;
                      break;
                    case 'common':
                      commons = action?.robotInfo?.kfInfo?.commons;
                      break;
                    case 'guess':
                      commons = action?.robotInfo?.kfInfo?.guide;
                      hideResult = true;
                      break;
                  }
                  additionalArr.push(new defaultDialogConfig({
                    msgTime: item.msgTime,
                    content: commons,
                    list: [...item?.attach_message]
                  }))
                }
                result.content = addImgTarget(item.message);
                break;
            }

            if (item.sendType === 3002 || item.sendType === 3003) {
              let msg;
              try {
                msg = JSON.parse(item.content || item.message)?.user_tip;
              } catch (e) {
              }
              result.content = msg || item.message;
              result.type = 'weakText';
            }


            dialogAdd = hideResult ? [...dialogAdd, ...additionalArr] : [...dialogAdd, result, ...additionalArr];
            return result;
          });
          return sortByMsgTime(dialogAdd, state);
          break;
        case 'delete':
          let result = [...state];
          if (action.param) {
            let index = result.findIndex((item) => item.msgId === action.param);
            if (index >= 0) {
              result.splice(index, 1);
              return result;
            }
          }
          return result;
          break;
        case 'addHistory':
          action.param.list.forEach((item) => {
            if (item.msgId || item.msg_id) {

              let alIndex = state.findIndex(i => {
                let absV = null;
                try {
                  absV = Math.abs(new Date(i.msgTime).getTime() - new Date(item.msgTime).getTime());
                } catch (e) {

                }
                return (i.msgId === item.msgId ||
                  (i.msgId !== item.msgId && i.content === item.message && (i.msgTime === item.msgTime || (absV && absV <= 2000))))
              });
              if (alIndex >= 0) {
                state[alIndex].msgId = item.msgId || item.msg_id;
                state[alIndex]._id = item._id || '';
                state[alIndex].chatType = item.chatType || '';
                return;
              }
            }
            let result = new defaultDialogConfig({
              type: parseInt(item.speakerType || item.speaker_type) !== 1 ? 'left' : 'right',
              innerHtml: true, // 富文本展示
              chatType: item.chatType,
              _id: item._id,
              msgId: item.msgId || item.msg_id || item._id,
              msgTime: item?.msgTime,
              robotId: item.robotId,
              speakerName: item.speakerName,
              contentList: item.ext_answer,
            });
            let additionalArr = [];
            let hideResult = false;
            switch (item.msgType) {
              case 3:
                // result
                // let file;
                // try {
                //   file = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.component = ChatFileItem;
                result.file = item?.message;
                break;
              case 2:
                // let img;
                // try {
                //   img = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.content = `<img src="${item?.message?.fileUrl}" onerror="this.src='https://p2.ssl.qhimg.com/t017638ac82012a6f59.png'" />`;
                break;
              default:
                if (item?.attach_message_type && Array.isArray(item?.attach_message)) {
                  let commons = '';
                  let tempArr;
                  let disable = false;
                  switch (item.attach_message_type) {
                    case 'recommend':
                      commons = action?.robotInfo?.kfInfo?.guess;
                      break;
                    case 'common':
                      // debugger;
                      // commons = action?.robotInfo?.kfInfo?.commons;
                      tempArr = [...item?.attach_message];
                      break;
                    case 'guess':
                      commons = action?.robotInfo?.kfInfo?.guide;
                      hideResult = true;
                      break;
                    case 'order_list':
                      tempArr = convertOrderList({select_type: 'order', list: item?.attach_message});
                      disable = true;
                      break;
                    case 'dialogue_select':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                      break;
                    case 'order_refund_reason':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                    default:
                      tempArr = convertOrderList({list: item?.attach_message});
                      break;
                  }
                  tempArr ? (result.list = tempArr , result.disable = disable) : additionalArr.push(
                    new defaultDialogConfig({
                      msgTime: item.msgTime,
                      content: commons,
                      disable,
                      list: [...item?.attach_message]
                    })
                  )
                }
                result.content = addImgTarget(item.message);
                break;
            }
            // content: item.message,

            if (item.sendType === 3002 || item.sendType === 3003) {
              let msg;
              try {
                msg = JSON.parse(item.content || item.message)?.user_tip;
              } catch (e) {
              }
              result.content = msg || item.message;
              result.type = 'weakText';
            }


            dialogAdd = hideResult ? [...dialogAdd, ...additionalArr] : [...dialogAdd, result, ...additionalArr];
            return result;
          });
          if (action?.param?.init) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                type: 'weakText',
                content: `———— 以上为历史消息 ————`,
                msgTime: action?.param?.msgTime
              })
            ]
          }
          return sortByMsgTime(dialogAdd, state);
          break;
        case 'coverHistory':
          action.param.list.forEach((item) => {
            let result = new defaultDialogConfig({
              type: parseInt(item.speakerType || item.speaker_type) !== 1 ? 'left' : 'right',
              innerHtml: true, // 富文本展示
              chatType: item.chatType,
              _id: item._id,
              msgId: item.msgId || item.msg_id || item._id,
              msgTime: item?.msgTime,
              robotId: item.robotId,
              speakerName: item.speakerName,
            })
            let additionalArr = [];
            let hideResult = false;
            switch (item.msgType) {
              case 3:
                // result
                // let file;
                // try {
                //   file = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.component = ChatFileItem;
                result.file = item?.message;
                break;
              case 2:
                // let img;
                // try {
                //   img = JSON.parse(item.message);
                // } catch (e) {
                //
                // }
                result.content = `<img src="${item?.message?.fileUrl}" onerror="this.src='https://p2.ssl.qhimg.com/t017638ac82012a6f59.png'" />`;
                break;
              default:
                if (item?.attach_message_type && Array.isArray(item?.attach_message)) {
                  let commons = '';
                  let tempArr;
                  let disable = false;
                  switch (item.attach_message_type) {
                    case 'recommend':
                      commons = action?.robotInfo?.kfInfo?.guess;
                      break;
                    case 'common':
                      commons = action?.robotInfo?.kfInfo?.commons;
                      break;
                    case 'guess':
                      commons = action?.robotInfo?.kfInfo?.guide;
                      hideResult = true;
                      break;
                    case 'order_list':
                      tempArr = convertOrderList({select_type: 'order', list: item?.attach_message});
                      disable = true;
                      break;
                    case 'order_refund_reason':
                      tempArr = convertOrderList({list: item?.attach_message});
                      disable = true;
                  }
                  tempArr ? (result.list = tempArr , result.disable = disable) : additionalArr.push(
                    new defaultDialogConfig({
                      msgTime: item.msgTime,
                      content: commons,
                      disable,
                      list: [...item?.attach_message]
                    })
                  )
                }
                result.content = addImgTarget(item.message);
                break;
            }
            // content: item.message,

            if (item.sendType === 3002 || item.sendType === 3003) {
              let msg;
              try {
                msg = JSON.parse(item.content || item.message)?.user_tip;
              } catch (e) {
              }
              result.content = msg || item.message;
              result.type = 'weakText';
            }


            dialogAdd = hideResult ? [...dialogAdd, ...additionalArr] : [...dialogAdd, result, ...additionalArr];
            return result;
          });
          if (action?.param?.init) {
            dialogAdd = [
              ...dialogAdd,
              new defaultDialogConfig({
                type: 'weakText',
                content: `———— 以上为历史消息 ————`,
                msgTime: action?.param?.msgTime
              })

            ]
          }


          return [
            ...dialogAdd,
          ]
          break;
        case 'showConnectIM':
          let imResult = [...state];
          imResult = imResult.map(item => {
            item.showIMBtn = false;
            return item;
          });
          if (action.msgId) {
            let index = imResult.findIndex((item) => item.msgId === action.msgId);
            imResult[index] && (imResult[index].showIMBtn = true) && (imResult[index].prompt = action.prompt);
          }
          return imResult;
          break;
        case 'addGuide':
          if (action.param.content) {
            dialogAdd = [new defaultDialogConfig({
              content: action.param.content,
              msgTime: action?.param?.msgTime,
              innerHtml: true, // 富文本展示
              hideCollapse: true,
            })]
          }
          return [
            ...state,
            ...dialogAdd
          ]
          break;
        case 'overwrite':
          if (Array.isArray(action.param)) {
            // debugger;
            let temp = action.param.filter(item => {
              if (!item.content) {
                return false;
              }
              item.typedInput = false;
              item.msgTime = item.msgTime || getMsgTime();
              if (item && item.file) {
                if (item.uniqueName || item?.file?.uniqueName) {
                  item && item.file && (item.component = ChatFileItem);
                } else {
                  item.component = ChatFileItem;
                  item.loading = 'error';
                  // return false;
                }
              }
              let imgReg = /<img.*?(?:>|\/>)/gi;
              if (item && (item.content && item.content.match && item.content.match(imgReg)) || item.msgType === 2) {
                if (item && item.imageUrl) {
                  item && item.imageUrl && (item.content = `<img src="${item.imageUrl}" />`);
                } else {
                  item.loading && (item.loading = 'error');
                  // return false;
                }

              }
              return true;
            });
            return [...temp];
          }
          break;
        default:
          return [];
          break;
      }
    }
  } catch (e) {
    return state;
  }

}


export default reducer;