import React, {useEffect, useState} from "react";

import styles from './index.less';
import Center from "./Center";
import Footer from "./Footer";
import Header from "./Header";

require('jquery');

const The360Plus = props => {
  const {info, isMobile, dialogList, gamesend, send, isGameRobot, change} = props;

  const [error, setError] = useState('');

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (info && info.error) {
      setError(info.error);
      console.log('info', info);
    }
  }, [info]);

  const init = () => {
    $('body').css({
      minWidth: '450px'
    })
  }

  const renderError = () => {
    return <div className={styles['error-container']}>
      <svg className={`icon ${styles['error-icon']}`} aria-hidden="true">
        <use xlinkHref={`#icon-zhike-error`}/>
      </svg>
      <br/>
      <p>
        {error}
      </p>

    </div>
  }

  const render = () => {
    if (error) {
      return renderError();
    }
    return (<div className={styles['the-360-plus-container']}>


      <div className={styles['the-360-plus-content']}>
        <Header info={info.kfInfo}/>
        <Center info={info} list={dialogList} isMobile={isMobile} triggerSend={send}
                triggerGameSend={gamesend}/>
        <Footer triggerSend={send} triggerChange={change} isMobile={isMobile} showChange={isGameRobot}/>
      </div>


    </div>);
  }

  return render();
}

export default The360Plus;