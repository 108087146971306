import React, {useState, useEffect, useRef} from 'react';
import styles from './index.less';



export default function ChooseBusiness(props) {
    const {
        info,
        isMobile = false,
        showImChooseBusiness = false,
        showBusinessType,
        closeReceptionModal = () =>{},
        receptionGroup = [],
        wsInit = () => {},
        setReception = () =>{},
    } = props;

     const onSubmit = value =>{
         let type = showBusinessType;
         let params = [value];
         setReception([value]);
         wsInit(params,type);
     };

    const renderMobile = () => {
        return <div>
                    <div
                        className={`${styles['choose-shade-container']} ${showImChooseBusiness ? styles['-mobile-active'] : styles['-mobile-hidden']} `}>
                    </div>
                    <div className={`${styles['choose-container']} ${showImChooseBusiness ? styles['-mobile-active'] : styles['-mobile-hidden']} `}>
                        <div className={styles['choose-title']}>
                          <span className={styles['choose-left']}> {info?.group_guide_msg||'请选择您要咨询的业务'}</span>
                          <span className={styles['choose-close']} onClick={closeReceptionModal}>x</span>
                        </div>

                        <div className={styles['choose-box']}>
                            {receptionGroup?.map((value,index)=>{
                                return(
                                    <div key={index} className={styles['choose-tip']} onClick={()=>{onSubmit(value?.reception_id)}}>
                                      <div className={styles['box-title']}>
                                        <img
                                          className={styles['choose-icon']}
                                          src={value?.logo || 'https://p1.ssl.qhimg.com/t0112b95440a2afe1a0.png'}
                                        />
                                        <div   className={styles['choose-content']}>
                                          <div className={styles['content-title']}>{value?.nickname}</div>
                                          <div className={styles['content-tips']}>{value?.description}</div>
                                        </div>
                                      </div>

                                      <div className={styles['choose-in']}>></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
        </div>
    };

    const render = () => {
        return <div>
                <div className={styles['pc-all-container']}>
                    <div
                        className={`${styles['shade-container']} ${showImChooseBusiness ? styles['-active'] : styles['-hidden']} `}></div>
                    <div className={`${styles['pc-container']} ${showImChooseBusiness ? styles['-active'] : styles['-hidden']} `}>
                        <div className={styles['pc-wrapper']}>
                            <span className={styles['title-word']}>{info?.group_guide_msg||'请选择您要咨询的业务'}</span>
                            <span className={styles['title-close']} onClick={closeReceptionModal}>x</span>
                        </div>
                        <div className={styles['pc-flexBox']}>
                            {receptionGroup?.map((value,index)=>{
                             return(
                                 <div key={index} className={styles['pc-flexTip']} onClick={()=>{onSubmit(value?.reception_id)}}>
                                     <img
                                       className={styles['pc-icon']}
                                       src={value?.logo || 'https://p1.ssl.qhimg.com/t0112b95440a2afe1a0.png'}
                                     />
                                   <div className={styles['pc-card']}>
                                     <span>{value?.nickname}</span>
                                     <div className={styles['pc-tips']}>{value?.description}</div>
                                   </div>
                                 </div>
                             )
                            })}
                        </div>

                    </div>
                </div>
        </div>
    };
    return (
        isMobile ? renderMobile() : render()
    )
}