const  addImgTarget = content => {
    let result = content;
    let imgReg = /<img.*?(?:>|\/>)/gi;
    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
    let arr = content.match(imgReg);
    if (Array.isArray(arr) && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
            let src = arr[i].match(srcReg);
            if (Array.isArray(src) && src[1]) {
                result = content.replace(arr[i], function() {
                    return `<img onerror="this.src='https://p2.ssl.qhimg.com/t017638ac82012a6f59.png'"  src = "${src[1]}" />`;
                });
            }
        }
    }
    return result;
};

export default addImgTarget
