import React, {useState, useRef, useEffect} from "react";
import styles from './index.less'

export default function Header(props) {
  const {info} = props;
  // console.log('info',info);
  const [showIntroduce, setShowIntroduce] = useState(false);

  const introduceRef = useRef();

  const handlerClick = (e) => {
    if(!showIntroduce && introduceRef && introduceRef.current.contains(e.target)){
      return;
    }
    setShowIntroduce(false);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handlerClick, false);
    return ()=>{
      document.removeEventListener('mousedown', handlerClick, false);
    }
  }, []);

  return (
    <div className={styles.kf_header}>
      <div className={styles['header-name']}>
        <span>{info.nickName}</span>
        <svg className={`icon ${styles['icon']}`} aria-hidden="true" onClick={() => {
          setShowIntroduce(true)
        }}>
          <use xlinkHref={`#icon-zhike-learn-more`}/>
        </svg>
      </div>

      <div ref={introduceRef} className={`${styles.introduce} ${showIntroduce ? styles['-active'] : ''}`}>

        <div className={styles['header']}>
          关于360知客
        </div>

        <div className={styles['content']}>
          <div className={styles['pic']}>
            <img src={info.url} alt=""/>
          </div>


          <p className={styles.title}>介绍</p>
          <p className={styles.desc}>360知客是由技术中台业务平台部团队打造智能客服平台（智能AI服务由数据平台部提供）。主要解决咨询类问题和用户常见问题，缩短问题解决周期。</p>

          <p className={styles.title}>支持团队</p>
          <p className={styles.desc}>流程管理部基于360知客智能客服平台，打造了客服机器人【智能小蜜】，旨在快速、高效解答我司员工对流程制度、职能服务、系统操作等常见问题，提升办公效率。</p>

          <p className={styles.title}>商务合作</p>
          <p className={styles.desc}>如有对接360知客意向或遇到使用，可联系：业务平台部 王刚 与 辛东鹏。</p>

        </div>

      </div>

    </div>
  )
}