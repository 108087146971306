import Login from "./login";
import {isMobile} from "../static/util/utils";
import queryString from "query-string";
import {getItemFormName} from "../static/util/store";

const {launch_id = '',v_Q,v_T,gkey,_c_u_i} = queryString.parse(location.search);

const FunList = [
  async ({rid}) => {
    if(!['d7204dc3-a36c-47f8-8c5d-b2743303b747','29829a26-5e4f-4482-bd7f-99b46b8b2128'].includes(rid)){
      return;
    };
    let loginC = new Login();
    await loginC.init({hideCloseBtn:true});
    let data = await loginC.getUserData();
    if (data) {
      return data;
    } else {
      if(isMobile()){
        window.location.href = `https://i.360.cn/login/wap?destUrl=${encodeURIComponent(window.location.href)}`
      }else{
        let res = await loginC.login();
        if(res){
          return data;
        }
      }
    }
  },
  async ({rid})=>{
    if(!['47c050eb-8132-4f02-b64a-238d17619f8c','538128f9-5489-4c1f-a1db-3b766c448e4a','6e3cd2f1-6a36-41d5-b12f-d0ad2bdd35c7'].includes(rid)){
      return;
    }else{
      if(_c_u_i && getItemFormName(rid) === _c_u_i ){
        return true;
      }
      throw new Error();
    }
  }

];

export default FunList;