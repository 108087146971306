import xssFilters from "xss";
import xssOptions from "../static/xssFiltersOptions";
import {generateWid, getUTag, uuid} from "../static/util/utils";
import LoadingComponent from "../components/LoadingComponent";
import Cookies from "js-cookie";
import {idHeader} from "../static/util/request";

const queryString = require('query-string');
const searchParams = queryString.parse(location.search);

const randomTag = getUTag();
const {rid = '6b4ac81a-0113-493a-aa8c-2a301549242b', sid = '', launch_id = '', code = '', appid = '',run_mode = ""} = queryString.parse(location.search);
async function AIControl({e,param, createEventSource, reducer, eventSourceData,eventSourceState,info,question} = {}) {

  const stringToSend = xssFilters(e.trim(), xssOptions);
  if (!stringToSend) {
    return
  }

  let questionTempMsgId = uuid();
  reducer({type: 'waitQuestion', param: stringToSend,id:questionTempMsgId});
  // console.log(e);

  let tempMsgId = uuid();
  reducer({
    type: 'dealResponse', param: {
      // content: `<div>123</div>`,
      component: LoadingComponent,
      // id: tempMsgId,
      msgId: tempMsgId,
    }
  });
  createEventSource({
    // http://10.16.49.85:10081/api/v1/ai_chat/ai_doc/question
    id: tempMsgId,
    stringToSend,
    questionId:questionTempMsgId,
    url: `/api/v1/submitQusV2`, options: {
      method: 'POST',
      timeout: 60000,
      // mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        // 'Content-Type': 'text/event-stream',
        'accept': '*/*',
        ...idHeader(),
      },
      body: JSON.stringify({
        rid,
        run_mode,
        // intent_id:param?.intent_id,
        wid: `${generateWid()}`,
        tag : info.uInfo.account || randomTag || (Cookies.get('uTag')),
        // "tag": "kO7gnaO4JxMKTbaBeuGbVKIfLWl5UT5C",
        "question": question || stringToSend,
        launch_id,
        ...param,
      })
    },

  });

}

export default AIControl;