
const xssOptions = {
  whiteList: {
    a: ['href', 'target'],
    img: ['src'],
    br:[],
  },
  stripIgnoreTag: true, // 过滤所有非白名单标签的HTML
  stripIgnoreTagBody: ['script', 'style'],
  css: false,
};

export default xssOptions;
