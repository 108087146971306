// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body, div, dl, dt, dd, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {\n  margin: 0;\n  padding: 0;\n}\n.root--250uw {\n  width: 100%;\n  height: 100%;\n  background: #E2E5E8;\n}\n.root--250uw.-with-side-bar--2Z-XT {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n.root--250uw.-full-screen--3LSGV .kf_box--30mVY {\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  transform: unset;\n  position: relative;\n  border: none;\n}\n.kf_box--30mVY {\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  border: 1px solid #e7e7e7;\n  width: 800px;\n  height: 80%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: #EFF2F5;\n}\n.kf_box--30mVY.-with-side-bar--2Z-XT {\n  width: 780px;\n  left: unset;\n  top: unset;\n  transform: unset;\n  flex-wrap: nowrap;\n  position: relative;\n  border: none;\n}\n.mkf_box--1EOEx {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: #f1f5f7;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "root--250uw",
	"-with-side-bar": "-with-side-bar--2Z-XT",
	"-full-screen": "-full-screen--3LSGV",
	"kf_box": "kf_box--30mVY",
	"mkf_box": "mkf_box--1EOEx"
};
module.exports = exports;
