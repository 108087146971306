import React, {useState, useEffect, useRef} from 'react';
import styles from './index.less';
import {useTranslation} from "react-i18next";


export default function Feedback(props) {
  const {
    info,
    isMobile = false,
    showFeedback = false,
    setShowFeedback = () => {

    },
    submitFeedback = () => {

    },
  } = props;

  const [likeState, setLikeState] = useState('1');
  const [starScore, setStarScore] = useState(5);
  const [text, setText] = useState('');

  const {t} = useTranslation();

  const scoreDescList = [
    t('dissatisfied'),
    t('disappointed'),
    t('general'),
    t('satisfaction'),
    t('perfect'),
  ]

  const feedbackClick = value => {
    setLikeState(value);
  }

  const closeFeedback = () => {
    setShowFeedback(false);

  };

  const canSubmit = () => {
    if (likeState != '' && starScore > 0) {
      return true;
    }
    return false;
  }


  function handleChange(e) {
    setText(e.target.value)
  }

  const submitHandle = () => {
    if (!canSubmit()) {
      return;
    }

    let params = {
      solved: likeState === '1' ? '1' : '0',
      score: starScore + "",
      remark: text,
      type: "1",
      comment_type: "1",
    };

    submitFeedback(params);

  }

  const renderMobile = () => {
    return <div>
      <div
        className={`${styles['shade-container']} ${showFeedback ? styles['-active'] : ''} `}>

      </div>

      <div className={`${styles['feedback-container']} ${showFeedback ? styles['-active'] : ''} ${starScore === 5 ? styles['-without-textarea'] : ''}`}>

        <div className={styles['feedback-header-container']}>
          <span>{t('feedback-title')}</span>
          <svg onClick={closeFeedback} className={`icon ${styles['close-btn']}`} aria-hidden="true">
            <use xlinkHref={`#icon-zhike-feedback-close`}/>
          </svg>
        </div>


        <div className={styles['feedback-content']}>
          <div className={styles['like-or-unlike-container']}>
            <p>{t('feedback-desc')}</p>
            <div className={`${styles['like-btn-group']} ${likeState != '' ? styles['-disable'] : ''}`}>
              <div className={`${styles['like-btn']} ${likeState === '1' ? styles['-active'] : ''}`}
                   onClick={() => feedbackClick('1')}>
                <svg className={`icon ${styles['icon']}`} style={{display: (likeState != '1' ? '' : 'none')}}
                     aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-like-gray`}/>
                </svg>
                <img style={{display: (likeState === '1' ? '' : 'none')}}
                     src="https://p4.ssl.qhimg.com/t01bce19cc99e72d6a3.png"/>
                <span>{t('solve')}</span>
              </div>
              <div className={`${styles['like-btn']} ${likeState === '2' ? styles['-active'] : ''}`}
                   onClick={() => feedbackClick('2')}>
                <svg className={`icon ${styles['icon']}`} style={{display: (likeState != '2' ? '' : 'none')}}
                     aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-unlike-gray`}/>
                </svg>
                <img style={{display: (likeState === '2' ? '' : 'none')}}
                     src="https://p0.ssl.qhimg.com/t01da1ac8d33a0eadc5.png"/>
                <span>{t('un-solve')}</span>
              </div>
            </div>


          </div>
        </div>

        <div className={styles['score-container']}>

          {
            [1, 2, 3, 4, 5].map((item, index) => {
              return <div className={styles['star-icon']} key={item} onClick={() => {
                setStarScore(item)
              }}>
                <svg style={{display: (starScore >= item ? 'none' : '')}} className={`icon ${styles['icon']}`}
                     aria-hidden="true">
                  <use xlinkHref={`#icon-zhike-unlike-star`}/>
                </svg>
                <img style={{display: (starScore >= item ? '' : 'none')}}
                     src="https://p5.ssl.qhimg.com/t0171e17a40594c4e69.png"/>
              </div>
            })
          }

          <p className={styles['score-desc']}>
            {
              starScore > 0 ? scoreDescList[starScore - 1] : ''
            }
          </p>

        </div>

        {starScore < 5 ? <div className={styles['feedback-text-container']}>
          <textarea
            placeholder={'欢迎给我们访问提建议~（选填）'}
            onChange={handleChange}
          />
        </div> : null}


        <div className={`${styles['submit-btn']} ${canSubmit() ? '' : styles['-disable']}`}
             onClick={submitHandle}
        >
          <span>{t('submit')}</span>
        </div>


      </div>
    </div>
  }


  return (
    isMobile && true ? renderMobile() : null
  )
}